import { FormPreventDefault } from 'components/Form';
import { FormEventHandler, FunctionComponent } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import Button from '../Button';

interface Props {
  cancelText?: string;
  secondaryText?: string | JSX.Element;
  confirmText?: string;
  onClose: () => void;
  onSecondaryAction?: () => void;
  onConfirm: FormEventHandler;
  confirming?: boolean;
  show: boolean;
  size?: 'sm' | 'lg' | 'xl'; //md is default
  title?: string;
  showFooter?: boolean;
  shouldModalFocusItself?: boolean;
  showConfirmButton?: boolean;
  showSecondaryButton?: boolean;
  showCancelButton?: boolean;
  disableConfirmButton?: boolean;
  preventBackgroundClick?: boolean;
  hideCloseX?: boolean;
  contentClassName?: string;
}

const Modal: FunctionComponent<Props> = ({
  children,
  cancelText = 'Cancel',
  confirmText = 'Done',
  secondaryText,
  onClose,
  onConfirm,
  onSecondaryAction,
  confirming,
  show,
  size,
  title,
  shouldModalFocusItself = true,
  showConfirmButton = true,
  showSecondaryButton = false,
  showCancelButton = true,
  preventBackgroundClick = false,
  hideCloseX = false,
  contentClassName,
  disableConfirmButton = false
}) => {
  return (
    <BModal enforceFocus={shouldModalFocusItself} show={show} onHide={onClose} centered size={size} backdrop={preventBackgroundClick ? 'static' : true} className={hideCloseX ? 'hide-close' : ''} keyboard={!hideCloseX} contentClassName={contentClassName}>
      <BModal.Header closeButton>
        {title && <BModal.Title className="fw-normal">{title}</BModal.Title>}
      </BModal.Header>
      <BModal.Body>{children}</BModal.Body>
      <BModal.Footer>
        {showCancelButton && (
          <Button variant="link" onClick={onClose}>
            {cancelText}
          </Button>
        )}
        {showSecondaryButton && (
          <Button variant="outline-primary" onClick={onSecondaryAction}>
            {secondaryText}
          </Button>
        )}
        {showConfirmButton && (
          <Button variant="primary" onClick={onConfirm} loading={confirming} disabled={disableConfirmButton} text="Saving...">
            {confirmText}
          </Button>
        )}
      </BModal.Footer>
    </BModal>
  );
};

const FormModal: FunctionComponent<Props> = ({
  children,
  cancelText = 'Cancel',
  confirmText = 'Done',
  onClose,
  onConfirm,
  confirming,
  show,
  size,
  title,
  showConfirmButton = true,
  showCancelButton = true,
  disableConfirmButton = false,
  contentClassName,
  preventBackgroundClick = false
}) => {
  return (
    <BModal show={show} onHide={onClose} centered size={size} contentClassName={contentClassName} backdrop={preventBackgroundClick ? 'static' : true}>
      <FormPreventDefault onSubmit={onConfirm}>
        <BModal.Header closeButton>
          {title && <BModal.Title className="fw-normal">{title}</BModal.Title>}
        </BModal.Header>
        <BModal.Body>{children}</BModal.Body>
        <BModal.Footer>
          {showCancelButton && (
            <Button variant="link" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          {showConfirmButton && (
            <Button variant="primary" type="submit" disabled={disableConfirmButton} loading={confirming} text="Saving...">
              {confirmText}
            </Button>
          )}
        </BModal.Footer>
      </FormPreventDefault>
    </BModal>
  );
};

export { Modal, FormModal };
