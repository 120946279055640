import { useState, useRef } from 'react';
import { Row, Col, Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { PropoundingParty, Respondent, type Client, type Case, type ExternalClient } from 'briefpoint-client';
import { CombinedClientSearch, mergeClients } from 'utils/ConfirmInfo/utils';
import { type Option } from 'react-bootstrap-typeahead/types/types';
import { BitField, BitSet } from 'easy-bits';
import { ReactComponent as SwapIcon } from "../../images/swap_vert.svg";
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { CustomMenuClient } from './utils';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RespondentDisplay } from 'services/DocumentService';
import styles from './ReviewPage.module.scss';
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { useAuth } from 'hooks/useAuth';
import useConfig, { MULTI_CLIENT_FF } from "hooks/useConfig";

// Parties
function ReviewPageThree({
  propoundingParty,
  setPropoundingParty,
  respondingParty,
  setRespondingParty,
  propoundingPartyName,
  setPropoundingPartyName,
  respondingPartyName,
  setRespondingPartyName,
  isCrossComplaint,
  client,
  clients,
  externalClients,
  _case,
  respondent,
  setRespondent,
  respondentCustom,
  setClient,
  setRespondentCustom
}: {
  propoundingParty: PropoundingParty | undefined;
  setPropoundingParty: React.Dispatch<React.SetStateAction<PropoundingParty | undefined>>;
  respondingParty: PropoundingParty | undefined;
  setRespondingParty: React.Dispatch<React.SetStateAction<PropoundingParty | undefined>>;
  propoundingPartyName: string;
  setPropoundingPartyName: React.Dispatch<React.SetStateAction<string>>;
  respondingPartyName: string;
  setRespondingPartyName: React.Dispatch<React.SetStateAction<string>>;
  isCrossComplaint: boolean;
  _case?: Case;
  client?: Client;
  clients?: Client[];
  externalClients?: ExternalClient[];
  respondent: Respondent;
  setRespondent: (respondent: Respondent) => void;
  respondentCustom: string;
  setClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
  setRespondentCustom: React.Dispatch<React.SetStateAction<string>>;
}) {

  const { user, firm } = useAuth()!;
  const [, featureFlags] = useConfig();
  const useMultiClient = featureFlags()[MULTI_CLIENT_FF] ? featureFlags()[MULTI_CLIENT_FF](user, firm) : false;

  let isCurClientMatch: boolean = (_case?.clientId !== undefined) && (client?.id !== undefined) && (_case?.clientId === client?.id);

  // if the current client matches, default to the 'find client' field
  const [clientAction, setClientAction] = useState<String>(isCurClientMatch || (!_case?.clientId && client?.id) ? "2" : "1");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const clientTypeaheadRef = useRef<any>();

  const clientActionTypes = [
    { name: 'New Client', value: '1' },
    { name: 'Existing Client', value: '2' }
  ];

  const respondents = [Respondent.Respondent, Respondent.RespondingParty, Respondent.PlaintiffOrDefendant, Respondent.Custom];

  const propoundingPartyConfig: BitSet<PropoundingParty> = new BitField<PropoundingParty>();
  propoundingPartyConfig.on(propoundingParty || PropoundingParty.Unknown);
  const respondingPartyConfig: BitSet<PropoundingParty> = new BitField<PropoundingParty>();
  if (respondingParty === undefined) {
    if (propoundingPartyConfig.test(PropoundingParty.Plaintiff)) {
      respondingPartyConfig.on(PropoundingParty.Defendant);
      if (isCrossComplaint ?? false) {
        respondingPartyConfig.on(PropoundingParty.CrossComplainant);
      }
    } else {
      respondingPartyConfig.on(PropoundingParty.Plaintiff);
      if (isCrossComplaint) {
        respondingPartyConfig.on(PropoundingParty.CrossDefendant);
      }
    }
  } else {
    respondingPartyConfig.on(respondingParty);
  }

  const propoundingPartyNameMap = new Map([
    [PropoundingParty.Plaintiff, 'Plaintiff'],
    [PropoundingParty.Defendant, 'Defendant'],
    [PropoundingParty.CrossComplainant, 'Cross-Complainant'],
    [PropoundingParty.CrossDefendant, 'Cross-Defendant'],
  ]);


  function handleClientChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      const clientVal = val as CombinedClientSearch;
      let _client = clients?.find(x => x.name === clientVal.name);

      if (!_client) {
        const integration = clientVal.partner ? { partner: clientVal.partner!, identifier: clientVal.externalId! } : undefined;
        _client = { name: clientVal.name, integration };
      }

      setClient(_client);

      setRespondingPartyName((val as any).name);
      setShowMenu(false);
      if (!!clientVal && !!clientVal?.internalId) {
        setShowMenu(false);
        setClientAction("2");
      } else {
        setClientAction("1");
      }
    }

  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    setClientAction(value);
    setClient(undefined);
    clientTypeaheadRef.current.focus();
    if (value === "1") {
      if (clients) {
        clientTypeaheadRef.current.toggleMenu();
      }
    }
  }

  function handleOnInputChange(text: string) {
    if (text) {
      setClient({ name: text });
      setRespondingPartyName(text);
      setClientAction("1");
      setShowMenu(true);
    } else {
      setClient(undefined);
      setShowMenu(false);
    }
  }

  function getRespondingPartyType() {
    for (let [key, value] of propoundingPartyNameMap) {
      if (respondingPartyConfig.test(key)) {
        return value;
      }
    }
    return '';
  }

  let respondingPartyType = getRespondingPartyType();

  function updatePropoundingParty(value: PropoundingParty) {
    propoundingPartyConfig.flip(value);
    setPropoundingParty(propoundingPartyConfig.valueOf() as PropoundingParty);
  }

  function updateRespondingParty(value: PropoundingParty) {
    respondingPartyConfig.flip(value);
    setRespondingParty(respondingPartyConfig.valueOf() as PropoundingParty);
  }

  const sortedClients: CombinedClientSearch[] = mergeClients(clients, externalClients).sort((a, b) => {
    const aHasPartnerNoId = a.partner && !a.internalId;
    const bHasPartnerNoId = b.partner && !b.internalId;

    if (aHasPartnerNoId && !bHasPartnerNoId) {
      return -1;
    } else if (!aHasPartnerNoId && bHasPartnerNoId) {
      return 1;
    }

    if (a.partner && !b.partner) {
      return -1;
    } else if (!a.partner && b.partner) {
      return 1;
    }

    const caseIdA = a.internalId || "";
    const caseIdB = b.internalId || "";
    if (!caseIdA && caseIdB) {
      return -1;
    } else if (caseIdA && !caseIdB) {
      return 1;
    }

    const shortTitleA = a.name || "";
    const shortTitleB = b.name || "";
    return shortTitleA.localeCompare(shortTitleB);
  });

  function swapParties() {
    setClient((prevClientName) => {
      // This check is to ensure that if we swap back to a respondingName that matches an existing client, we take the integration 
      // info that matches with it so that the logo in the typeahead accurately reflects whether the client is external/internal
      let existingClient = sortedClients?.find(x => x.name === propoundingPartyName);
      let integration;

      if (existingClient) {
        integration = existingClient.externalId ? { partner: existingClient.partner!, identifier: existingClient.externalId } : undefined;
      }

      return { ...prevClientName, name: propoundingPartyName, ...(integration ? { integration } : { integration: undefined })
      };
    });
    setRespondingPartyName(propoundingPartyName);
    setPropoundingPartyName(respondingPartyName);
    setPropoundingParty(respondingParty);
    setRespondingParty(propoundingParty);
  }


  const getExternalPartnerStyle = () => {
    if (client?.integration?.partner) {
      return styles[`external-partner-${client.integration.partner}`];
    }

    return styles['external-partner-none'];
  }

  return (
    <div>
      <div className="mb-2">
        <Form.Group>
          <label>Propounding Party</label>
          <Form.Control
            required
            className="mb-3"
            size="sm"
            value={propoundingPartyName}
            onChange={(event) => setPropoundingPartyName(event.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group>
          <Row>
            <Col xs="6">
              <Form.Check
                id={'propounding-party-plaintiff'}
                type={'checkbox'}
                label="Plaintiff"
                checked={propoundingPartyConfig.test(PropoundingParty.Plaintiff)}
                onChange={() => updatePropoundingParty(PropoundingParty.Plaintiff)}
                isInvalid={propoundingParty === PropoundingParty.Unknown}
              />
            </Col>
            <Col xs="6">
              <Form.Check
                id={'propounding-party-cross-defendant'}
                type={'checkbox'}
                label="Cross-Defendant"
                checked={propoundingPartyConfig.test(PropoundingParty.CrossDefendant)}
                onChange={() => updatePropoundingParty(PropoundingParty.CrossDefendant)}
                isInvalid={propoundingParty === PropoundingParty.Unknown}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Form.Check
                id={'propounding-party-defendant'}
                type={'checkbox'}
                label="Defendant"
                checked={propoundingPartyConfig.test(PropoundingParty.Defendant)}
                onChange={() => updatePropoundingParty(PropoundingParty.Defendant)}
                isInvalid={propoundingParty === PropoundingParty.Unknown}
              />
            </Col>
            <Col xs="6">
              <Form.Check
                id={'propounding-party-cross-complainant'}
                type={'checkbox'}
                label="Cross-Complainant"
                checked={propoundingPartyConfig.test(PropoundingParty.CrossComplainant)}
                onChange={() => updatePropoundingParty(PropoundingParty.CrossComplainant)}
                isInvalid={propoundingParty === PropoundingParty.Unknown}
              />
            </Col>
          </Row>
        </Form.Group>
      </div>
      <Form.Group>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <div style={{ flex: 1, height: '1px', backgroundColor: '#d2d5d5' }} />
          {!isCurClientMatch && (
            <div>
              <SwapIcon color='#4d5758' title='Swap Parties' style={{ cursor: "pointer", width: '24px', height: '24px', textAlign: 'center' }} onClick={swapParties} />
            </div>
          )}
          <div style={{ flex: 1, height: '1px', backgroundColor: '#d2d5d5' }} />
        </div>
        {!isCurClientMatch && (
          <div className={styles.caseSwitchToggle}>
            <ButtonGroup className={styles.bpToggleTxtSwitch}>
              {clientActionTypes.map((type, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  className={clientAction === type.value ? styles.btnChecked : ''}
                  type="radio"
                  name="radio"
                  value={type.value}
                  checked={clientAction === type.value}
                  onChange={handleToggleChange}
                >
                  {type.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        )}
        <div className={styles.checkMatchContainer}>
          {!isCurClientMatch && <><ExclamationCircleFill /> <p>This client doesn't match an existing client</p></>}
        </div>
        <Form.Group>
          <label>Responding Party (Client)</label>
          {useMultiClient || !isCurClientMatch ? <Typeahead
            className={getExternalPartnerStyle()}
            onChange={(e) => handleClientChange(e)}
            onInputChange={handleOnInputChange}
            selected={(client && [client]) || []}
            clearButton
            isInvalid={!client}
            id="client"
            size="sm"
            emptyLabel={clientAction === "1" && "No matches found, this will create a new client."}
            options={sortedClients || []}
            // Disable the menu on click when toggle is switch to 'new client' to prevent initial menu pop up
            onFocus={clientAction === "1" ? () => setShowMenu(false) : () => setShowMenu(true)}
            onBlur={()=>{setShowMenu(false) }}
            open={showMenu}
            labelKey='name'
            flip={false}
            placeholder={clientAction === "1" ? "Create a new client..." : "Find an existing client..."}
            renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
              <CustomMenuClient results={results} menuProps={menuProps} state={state} showSourceLogos={!!user?.externalConnection?.isActive}  isClientNew={clientAction === "1" ? true : false}/> 
            )}
            ref={clientTypeaheadRef}
          /> : <p>{client?.name}</p>
          }
        </Form.Group>
        <div className="mb-2" >
          <Row>
            <Col xs="6">
              <Form.Check
                id={'responding-party-plaintiff'}
                type={'checkbox'}
                label="Plaintiff"
                checked={respondingPartyConfig.test(PropoundingParty.Plaintiff)}
                onChange={() => updateRespondingParty(PropoundingParty.Plaintiff)}
                isInvalid={respondingParty === PropoundingParty.Unknown}
              />
            </Col>
            <Col xs="6">
              <Form.Check
                id={'responding-party-cross-defendant'}
                type={'checkbox'}
                label="Cross-Defendant"
                checked={respondingPartyConfig.test(PropoundingParty.CrossDefendant)}
                onChange={() => updateRespondingParty(PropoundingParty.CrossDefendant)}
                isInvalid={respondingParty === PropoundingParty.Unknown}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Form.Check
                id={'responding-party-defendant'}
                type={'checkbox'}
                label="Defendant"
                checked={respondingPartyConfig.test(PropoundingParty.Defendant)}
                onChange={() => updateRespondingParty(PropoundingParty.Defendant)}
                isInvalid={respondingParty === PropoundingParty.Unknown}
              />
            </Col>
            <Col xs="6">
              <Form.Check
                id={'responding-party-cross-complainant'}
                type={'checkbox'}
                label="Cross-Complainant"
                checked={respondingPartyConfig.test(PropoundingParty.CrossComplainant)}
                onChange={() => updateRespondingParty(PropoundingParty.CrossComplainant)}
                isInvalid={respondingParty === PropoundingParty.Unknown}
              />
            </Col>
          </Row>
        </div>
      </Form.Group>
      <div>
        <Form.Group>
          <label>Refer to responding party as</label>
          <Form.Select
            required
            value={respondent}
            size="sm"
            onChange={(event) => setRespondent(Number(event.currentTarget.value))}
          >
            {respondents.map((respondent, index) => (
              <option key={index} value={respondent}>
                {respondent === Respondent.PlaintiffOrDefendant
                  ? respondingPartyType
                  : RespondentDisplay[respondent]}
              </option>
            ))}
          </Form.Select>
          {respondent === Respondent.Custom && (
            <Form.Control
              required
              placeholder="Enter custom name"
              className="mt-2"
              size="sm"
              defaultValue={respondentCustom}
              onChange={(event) => setRespondentCustom(event.currentTarget.value)}
            />
          )}
        </Form.Group>
      </div>
    </div >
  );
}

export default ReviewPageThree; 
