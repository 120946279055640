import { Client, ExternalClient, ExternalPartners } from "briefpoint-client";

export interface CombinedClientSearch {
  name: string;
  partner?: ExternalPartners;
  externalId?: string;
  internalId?: string;
}

export const mergeClients = (clients?: Client[], externalClients?: ExternalClient[]) => {

  let mappedClients: CombinedClientSearch[];
  mappedClients  = clients?.map(c => ({ name: c.name ?? '', partner: c.integration?.partner, externalId: c.integration?.identifier ?? undefined, internalId: c.id })) ?? [];

  if (!externalClients?.length) {
    return mappedClients;
  }

  let mappedExternal: CombinedClientSearch[] = externalClients.map((c: any) => ({
    name: c.name!,
    partner: c.partner!,
    externalId: c.integration?.identifier!,
    internalId: undefined
  }));

  for (let i = 0; i < mappedExternal.length; i++) {
    const element = mappedExternal[i];
    const internal = mappedClients.find(x => x.partner === element.partner && x.externalId === element.externalId);

    if (internal) {
      element.internalId = internal.internalId;
      mappedClients = mappedClients.filter(c => c.internalId !== internal.internalId);
    }

  }

  return mappedClients.concat(mappedExternal);
}
