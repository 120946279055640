import { useEffect, useState } from 'react';
import { Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";
import { RenderMenuProps } from 'react-bootstrap-typeahead/types/components/Typeahead';
import { CombinedCaseSearch } from './ReviewPageOne';
import { CombinedClientSearch } from 'utils/ConfirmInfo/utils';
import styles from "./ReviewPage.module.scss";
import { Case, Client, ExternalClientIEnumerableExternalPartnerResponse, ExternalPartners, CollectionItem } from 'briefpoint-client';
import { ReactComponent as BriefpointLogo } from '../../images/logo.svg';
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';

function getCaseSourceLogo(showSourceLogos: boolean, partner?: ExternalPartners) {
  if (!showSourceLogos) {
    return null;
  }

  if (!partner) {
    return <BriefpointLogo title="Briefpoint Case" />;
  }

  switch (partner!) {
    case ExternalPartners.Clio:
      return <ClioLogo title="Clio Case" />;
    case ExternalPartners.Smokeball:
      return <SmokeballLogo title="Smokeball Case" />;
    case ExternalPartners.MyCase:
      return <MyCaseLogo title="MyCase Case" />;
    default:
      return <BriefpointLogo title="Briefpoint Case" />;
  }
}

export function remapExternalClientList(
  res: ExternalClientIEnumerableExternalPartnerResponse,
) {
  return res.data?.map((res) => ({
    contactFirstName: res.firstName,
    contactLastName: res.lastName,
    partner: res.partner,
    integration: { identifier: res.id, partner: res.partner },
    name: res.name,
    ...(res.email ? { contactEmail: res.email } : {})  // Add the external client's email if it exists
  }));
}

export function CustomMenuClient({ results, menuProps, state, showSourceLogos, isClientNew }: { results: any, menuProps: RenderMenuProps, state: any, showSourceLogos: boolean, isClientNew: boolean  }) {

  const processedResults = results.reduce((acc: CombinedClientSearch[], current: CombinedClientSearch) => {
    const existingIndex = acc.findIndex(item => item.name === current.name);
    
    if (existingIndex === -1) {
      acc.push(current);
    } else {
      const existing = acc[existingIndex];
      if (!existing.internalId && current.internalId) {
        acc[existingIndex] = current;
      }
    }
    
    return acc;
  }, []);

  return (
    <Menu {...menuProps}>
      {processedResults.map((option: CombinedClientSearch, index: number) => {

        if (!isClientNew && !option.partner && !option.internalId && !option.externalId) return null;

        return (
          <MenuItem
            key={index}
            option={option}
            position={index}
          >
            {option.internalId
              ? <p className={styles.customMenuLabel}>
                {getCaseSourceLogo(showSourceLogos, option.partner)}<Highlighter search={state.text}>{option?.name!}</Highlighter>
              </p>
              : (
                <div>
                  {!option.partner && (
                    <small>
                      <i>Suggested name for New Client:</i>
                    </small>
                  )}
                  <p className={styles.customMenuLabel}>
                    {option.partner && getCaseSourceLogo(showSourceLogos, option.partner)}<Highlighter search={state.text}>{option?.name!}</Highlighter>
                  </p>
                </div>
              )}
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export function NoSuggestCustomMenuClient({ results, menuProps, state, showSourceLogos }: { results: any, menuProps: RenderMenuProps, state: any, showSourceLogos: boolean }) {
  return (
    <Menu {...menuProps}>
      {results.map((option: Client, index: number) => (
        <MenuItem
          key={index}
          option={option}
          position={index}
        >
          <p className={styles.customMenuLabel}>
            {getCaseSourceLogo(showSourceLogos, option.integration?.partner)}<Highlighter search={state.text}>{(option as any).customOption ? `Create client: ${state.text}` : option?.name!}</Highlighter>
          </p>
        </MenuItem>
      ))}
    </Menu>
  )
}

export function CustomMenu({ results, menuProps, state, showSourceLogos }: { results: any, menuProps: RenderMenuProps, state: any, showSourceLogos: boolean }) {
  return (
    <Menu {...menuProps}>
      {results.map((option: CombinedCaseSearch, index: number) => (
        <MenuItem
          key={index}
          option={option}
          position={index}
        >
          {option.internalId || option.externalId
            ? <p className={styles.customMenuLabel}>
              {getCaseSourceLogo(showSourceLogos, option.partner)}<Highlighter search={state.text}>{option?.title!}</Highlighter>
            </p>
            : (
              <div>
                <small>
                  <i>Suggested name for New Case:</i>
                </small>
                <p className={styles.customMenuLabel}>{getCaseSourceLogo(showSourceLogos, option.partner)}
                  <Highlighter search={state.text}>{(option as any).customOption ? (option as any).label : option?.title!}</Highlighter>
                </p>
              </div>
            )}
        </MenuItem>
      ))}
    </Menu>
  )
}

export function NoSuggestCustomMenu({ results, menuProps, state, showSourceLogos }: { results: any, menuProps: RenderMenuProps, state: any, showSourceLogos: boolean }) {
  return (
    <Menu {...menuProps}>
      {results.map((option: Case, index: number) => (
        <MenuItem
          key={index}
          option={option}
          position={index}
        >
          {option.integration?.partner 
            ? <p className={styles.customMenuLabel}>
              {getCaseSourceLogo(showSourceLogos, option.integration.partner)}<Highlighter search={state.text}>{option?.shortTitle!}</Highlighter>
            </p>
            : (
              <div>
                <p className={styles.customMenuLabel}>{getCaseSourceLogo(showSourceLogos, option.integration?.partner)}
                  <Highlighter search={state.text}>{(option as any).customOption ? `Create case: ${state.text}` : option?.shortTitle!}</Highlighter>
                </p>
              </div>
            )}
        </MenuItem>
      ))}
    </Menu>
  )
}

export function NoSuggestCustomMenuExternalUpload({ results, menuProps, state, showSourceLogos }: { results: any, menuProps: RenderMenuProps, state: any, showSourceLogos: boolean }) {
  return (
    <Menu {...menuProps}>
      {results.map((option: CombinedCaseSearch, index: number) => (
        <MenuItem
          key={index}
          option={option}
          position={index}
        >
          {option?.partner
            ? <p className={styles.customMenuLabel}>
              {getCaseSourceLogo(showSourceLogos, option.partner)}<Highlighter search={state.text}>{option?.title!}</Highlighter>
            </p>
            : (
              <div>
                <p className={styles.customMenuLabel}>{getCaseSourceLogo(showSourceLogos, option?.partner)}
                  <Highlighter search={state.text}>{(option as any).customOption ? `Create case: ${state.text}` : option?.title!}</Highlighter>
                </p>
              </div>
            )}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default function usePositionActive(): [
  React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>,
  React.Dispatch<React.SetStateAction<HTMLSpanElement | undefined>>,
  React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>
] {
  const [activeRef, setActiveRef] = useState<HTMLDivElement>();
  const [activeLineRef, setActiveLineRef] = useState<HTMLSpanElement>();
  const [informationRef, setInformationRef] = useState<HTMLDivElement>();

  useEffect(() => {
    function handleResize() {
      if (activeRef && activeLineRef) {
        var activeBoundingRect = activeRef.getBoundingClientRect();
        var activeLineBoundingRect = activeLineRef.getBoundingClientRect();
        activeLineRef.style.width = activeLineBoundingRect.right - activeBoundingRect.right + 'px';
        if (informationRef) {
          var informationBoundingRect = informationRef.getBoundingClientRect();
          informationRef.style.top = activeLineBoundingRect.top - informationBoundingRect.height / 1.55 + 'px';
        }
      }
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [activeRef, activeLineRef, informationRef]); // Empty array ensures that effect is only run on mount

  return [setActiveRef, setActiveLineRef, setInformationRef];
}

// PLEADING UTILITY FUNCTIONS

export const pleadCollectionRemove = (
  index: number,
  arr: CollectionItem[],
  stateFunc: React.Dispatch<React.SetStateAction<CollectionItem[] | null>>,
) => {
  if (arr !== null) {
    const newAllegations: CollectionItem[] = arr.filter((_, i) => i !== index);

    stateFunc(newAllegations.length > 0 ? newAllegations : []);
  }
};

export const pleadCollectionChange = (
  index: number,
  newText: string,
  arr: CollectionItem[],
  stateFunc: React.Dispatch<React.SetStateAction<CollectionItem[] | null>>,
) => {
  if (arr !== null) {
    const newCollection = arr.map((item, i) =>
      i === index ? { ...item, text: newText } : item
    );
    stateFunc(newCollection);
  }
};

export const pleadCollectionAdd = (
  arr: CollectionItem[] | null,
  stateFunc: React.Dispatch<React.SetStateAction<CollectionItem[] | null>>,
) => {
  if (arr && arr !== null) {
    const lastItem = arr[arr.length - 1];
    const newNumber = lastItem?.number !== undefined ? lastItem.number + 1 : 1;
    const newItem: CollectionItem = { number: newNumber, text: "" };
    stateFunc([...arr, newItem]);
  } else {
    stateFunc([{ number: 1, text: "" }]);
  }
};

// Simple format the name of the generic info entry's name to remove underscore
export const formatGenericInfoName = (key: string) => {
  return key
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

// NOTE: Revisit this functionality if needed for generic info
export const genericInfoHandleAdd = (
  obj: { [key: string]: string } | null | undefined, 
  setState: React.Dispatch<React.SetStateAction<{ [key: string]: string } | null | undefined>>
) => {
  if (obj) {
    const newKey = `New_Detail_${Object.keys(obj).length + 1}`;
    setState({ ...obj, [newKey]: "" });
  } else {
    setState({ New_Detail_1: "" });
  }
};

export const genericInfoHandleRemove = (
  key: string,
  obj: { [key: string]: string } | null | undefined,
  setState: React.Dispatch<React.SetStateAction<{ [key: string]: string } | null | undefined>>,
) => {
  if (obj) {
    const { [key]: _, ...rest } = obj;
    setState(Object.keys(rest).length > 0 ? rest : null);
  }
};

export const genericInfoHandleChange = (
  key: string,
  newValue: string,
  obj: { [key: string]: string } | null | undefined,
  setState: React.Dispatch<React.SetStateAction<{ [key: string]: string } | null | undefined>>,
) => {
  if (obj) {
    setState({ ...obj, [key]: newValue });
  }
};
