import { customSelectStyles } from 'components/FederalSelect';
import { useMemo } from 'react';
import ReactSelect from 'react-select';

export interface CaseTypeOption {
  label: string;
  value: string | null;
  key: string;
}

interface Props {
  options: CaseTypeOption[];
  className?: string;
  selected?: string;
  onChange: (value: string) => void;
}

export default function Select({
  options,
  className,
  selected,
  onChange,
}: Props) {

  const selectedOption = useMemo(() => {
    return options.find((o) => o.value === selected);
  }, [selected, options]);

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  const transformedOptions = useMemo(() => {
    const group = groupBy(options, i => i.key);
    const opts = []

    for (let k in group) {
      opts.push({ key: k, options: group[k].map(v => { return { label: v.label, value: v.value }; }) });
    }

    return opts;
  }, [options]);

  const formatGroupLabel = (option: any) => {
    return (
      <div>{option.key}</div>
    );
  };

  const handleChange = (option: any) => {
    onChange(option.value);
  };

  return (
    <ReactSelect
      className='select'
      id="case-type"
      //classNamePrefix="brief-point-select"
      options={transformedOptions}
      styles={customSelectStyles}
      value={selectedOption?.label ? { label: selectedOption?.label, value: selectedOption?.value } : undefined}
      onChange={handleChange}
      formatGroupLabel={formatGroupLabel}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#074f6b',
          primary25: '#efefea',
        },
      })}
      //menuIsOpen={true}
      placeholder='Required please select...'
      menuPlacement='auto'
    ></ReactSelect>
  );
}
