import { Document, DocumentStatus, DocumentType, DocumentSubType, PropoundingParty, SubscriptionStatus, Case, Client, CommunicationStatus, ExternalClient, Role, Party, ExternalClientIEnumerableExternalPartnerResponse, ViewCommunication } from 'briefpoint-client';
import DocumentList from 'components/DocumentList/DocumentList';
import EmptyList from 'components/DocumentList/EmptyList';
import ProcessingBox from 'components/DocumentList/ProcessingBox';
import Help from 'components/Help';
import Loading from 'components/Loading';
import IntroTutorialModal from 'components/Modals/IntroTutorialModal';
import SignUpModal from 'components/Modals/SignUpModal';
import TrialEndsBanner, { GetTrialBannerMessage } from 'components/TrialEndsBanner';
import { useAuth } from 'hooks/useAuth';
import useDocuments from 'hooks/useDocuments';
import { useInterval as UseInterval } from 'hooks/useInterval';
import rg4js from 'raygun4js';
import useFirm from "hooks/useFirm";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Container, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { DocumentClient, sanitizeFiles } from 'services/DocumentService';
import { FirmClient, hasCanceledSubscription, isInTrialOrHasActiveSubscription } from 'services/FirmService';
import { classes } from 'utils';
import './DocumentList.scss';
import { HashLink as Link } from "react-router-hash-link";
import { useCaseApi, useClientApi, useCommunicationApi, useFirmApi, useTagApi } from 'hooks/useApi';
import AddEditCaseModal, { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';
import ExternalFileBrowserSelect from 'components/ExternalPartner/ExternalFileBrowserSelect';
import ExternalPartnerMenu from 'components/ExternalPartner/ExternalPartnerMenu';
import ExternalPartnerConnection from 'components/ExternalPartner/ExternalPartnerConnection';
import useConfig, { BRIDGE_FF, RIBBON_FF } from 'hooks/useConfig';
import { filterTagsForParties, loadTags } from 'utils/Requests/utils';
import { TopRibbon } from 'components/TopRibbon/TopRibbon';
import SelectPartyModal from './DocumentGeneration/SelectPartyModal';
import RequiredCaseInfo from './DocumentGeneration/RequiredCaseInfo';
import GeneralInformationScreen from 'components/ConfirmInfo/GeneralInformation';
import { externalRequestWrapper, handleExternalReqResCodes, initiateAuth } from 'utils/ExternalPartner/utils';
import { blankId } from 'components/ConfirmInfo/SourceDocumentReview';
import QuickActionModal from 'components/Modals/QuickActionModal';
import { remapExternalClientList } from 'components/ConfirmInfo/utils';
import PropoundSelectionModal from 'components/Modals/PropoundSelectionModal';

export function getUploadingDocument(name: string): DocumentClient {
  return {
    id: `${name}-${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5)
      }`,
    name,
    info: {
      documentType: DocumentType.Unknown,
      documentSubType: DocumentSubType.Unknown,
      venue: "",
      jurisdiction: "",
      propoundingParty: PropoundingParty.Unknown,
      propoundingPartyName: "",
      respondingPartyName: "",
      caseNumber: "",
      setNumber: 1,
      startingNumber: 1,
    },
    uploadDate: new Date(),
    status: DocumentStatus.Unknown,
    isArchived: false,
  } as DocumentClient;
}

const hasSeenSignUpModalStorageKey = "hasSeenSignUpModal";

export default function DocumentListScreen() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const [
    documents,
    loadDocuments,
    uploadFiles,
    archiveDocument,
    ,
    updateDocument,
    downloadForm,
    ,
    downloadRequest,
  ] = useDocuments();
  const location = useLocation();
  const didSignUp = new URLSearchParams(location.search).get("signedup") ?? undefined;
  const [isSignUpModalShow, setIsSignUpModalShow] = useState(false);
  const [isSignUpModalQuery, setIsSignUpModalQuery] = useState(false);
  const [isIntroTutorialModalShow, setIsIntroTutorialModalShow] = useState(
    false,
  );
  const [isOver, setIsOver] = useState(false);
  const { user, firm, jurisdictions, updatePreferences } = useAuth()!;
  const [allCases, setAllCases] = useState<Case[] | undefined>();
  const [allClients, setAllClients] = useState<Client[] | undefined>();
  const [showUploadDocs, setShowUploadDocs] = useState(false);
  const firmApi = useFirmApi();
  const clientApi = useClientApi();
  const caseApi = useCaseApi();
  const tagApi = useTagApi();
  const history = useHistory();
  const infoLoading = useRef(false);
  // QUICK ACTIONS
  const [users, , , ,] = useFirm(firm?.id, false);
  const [showPropoundIntroModal, setShowPropoundIntroModal] = useState<boolean>(false);
  const [isPleadingUpload, setIsPleadingUpload] = useState<boolean>(false);
  const [isQuickActionModalShow, setIsQuickActionModalShow] = useState<boolean>(false);
  const [isQuickActionUploadShow, setIsQuickActionUploadShow] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [isExternalEdit, setIsExternalEdit] = useState<boolean>(false);
  const [quickActionCase, setQuickActionCase] = useState<Case | undefined>();
  const [quickActionClient, setQuickActionClient] = useState<Client | undefined>();
  const [quickActionExternalClients, setQuickActionExternalClients] = useState<ExternalClient[] | undefined>();
  const [quickActionType, setQuickActionType] = useState<DocumentSubType | undefined>();
  const [quickActionIsSaving, setQuickActionIsSaving] = useState<boolean>(false);
  const [quickActionError, setQuickActionError] = useState<string | undefined>();
  const [showAddNewCase, setShowAddNewCase] = useState<boolean>(false);
  const [typeToGenerate, setTypeToGenerate] = useState<DocumentSubType>();
  const [tags, setTags] = useState<Map<number, string> | undefined>();
  const partyTags = useRef(new Map<number, string>());
  const pendingDraftType = useRef<DocumentSubType>();
  const [uploadingDocuments, setUploadingDocuments] = useState<DocumentClient[]>([]);
  const [isExternalUploading, setIsExternalUploading] = useState<boolean>(false);
  const communicationApi = useCommunicationApi();
  const [communications, setCommunications] = useState<ViewCommunication[] | undefined>();
  const [, featureFlags] = useConfig();
  const useBridge = featureFlags()[BRIDGE_FF] ? featureFlags()[BRIDGE_FF](user, firm) : false;
  const useRibbon = featureFlags()[RIBBON_FF] ? featureFlags()[RIBBON_FF](user, firm) : false;

  interface ProgressState {
    [key: string]: number;
  }

  const hasProvidedInfo = user?.firstName &&
    user?.lastName &&
    user?.barNumber &&
    (!user?.role?.includes(Role.FirmAdmin) || (firm?.name &&
      firm?.address?.street &&
      firm?.address.postalCode));

  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const firmJx = firm.offices?.find((x) =>
      x.jurisdictions?.length
    )?.jurisdictions![0]?.jurisdictionId ?? CaliforniaId;
    const jx = jurisdictions?.find((j) =>
      j.id === quickActionCase?.jurisdiction
    ) ?? jurisdictions?.find((j) => j.id === firmJx);
    return jx;
  }, [jurisdictions, firm, quickActionCase?.jurisdiction]);

  const missingRequiredInfo = useMemo(() => {
    return !quickActionCase?.caseType || !quickActionCase?.clientPosition;
  }, [quickActionCase]);
  const [estimatedProgress, setEstimatedProgress] = useState<ProgressState>({});

  function setProgress(id: string, progress: number) {
    setEstimatedProgress((prevProgress) => ({
      ...prevProgress,
      [id]: progress,
    }));
  }
  function noOp(id: string, progress: number) { }

  const getFirmJx = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const firmJx = firm.offices?.find((x) => x.jurisdictions?.length)
      ?.jurisdictions![0]?.jurisdictionId;
    const jx = jurisdictions?.find((j) => j.id === firmJx);
    return jx;
  }, [jurisdictions, firm]);

  const supportsFrogs = getFirmJx?.id === CaliforniaId;
  const nonArchivedDocs = documents?.filter((x) => !x.isArchived);
  const processingDocs = nonArchivedDocs?.filter((x) =>
    (
      (x.status || 0) < DocumentStatus.Processed
    ) ||
    x.status === DocumentStatus.Error ||
    x.status === DocumentStatus.Processed
  ).sort((a, b) => {
    if (a.uploadDate < b.uploadDate) return 1;
    if (a.uploadDate > b.uploadDate) return -1;

    return a.id.localeCompare(b.id);
  });

  let processingDocuments = nonArchivedDocs?.filter(
    (d: Document) => (d.status || 0) < DocumentStatus.Processed,
  );

  let tempConfirmedDocs = nonArchivedDocs?.filter(
    (d: Document) =>
      d.status === DocumentStatus.InfoConfirmed ||
      d.status === DocumentStatus.Complete,
  );

  const recentComms = tempConfirmedDocs?.filter((d: Document) => {
    const comm = communications?.find(x => x.objectId === d.id);
    return comm?.lastActivityDate && comm.status === CommunicationStatus.Complete && (!d.info?.lastViewDate || comm.lastActivityDate < d.info.lastViewDate);
  });

  tempConfirmedDocs = tempConfirmedDocs?.filter((d: Document) => {
    const comm = communications?.find(x => x.objectId === d.id);
    return !comm?.lastActivityDate || comm.status !== CommunicationStatus.Complete || (d.info?.lastViewDate && comm.lastActivityDate > d.info.lastViewDate);
  });

  async function updateDocCaseClient(
    doc: DocumentClient,
    _case?: Case,
    client?: Client,
  ) {
    await updateDocument(doc);
    if (_case?.id && !allCases?.find((x) => x.id === _case.id)) {
      setAllCases((a) => a ? [...a, _case] : [_case]);
    }

    if (client?.id && !allClients?.find((x) => x.id === client.id)) {
      setAllClients((a) => a ? [...a, client] : [client]);
    }
  }

  const hasDocuments = !!tempConfirmedDocs?.length;
  UseInterval(
    loadDocuments,
    processingDocuments && processingDocuments.length > 0 ? 1000 : null,
  );

  function handleChooseFile(e: React.MouseEvent) {
    e.preventDefault();
    fileInputRef?.current?.click();
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    await handleDropFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    setIsOver(true);
    e.preventDefault();
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsOver(false);
    e.preventDefault();
  };

  async function handleRequiredCase(_case: Case) {
    await caseApi.casePut({ firmId: user?.firmId!, id: _case.id!, _case });
    setQuickActionCase(_case);
    await loadTags(setTags, partyTags, tagApi);
  }

  const handleDismissTutorial = useCallback(() => {
    setIsIntroTutorialModalShow(false);

    if (user?.preferences) {
      user.preferences.hasSeenIntroTutorial = true;
      updatePreferences(user.preferences);
    }
  }, [user, updatePreferences]);

  const loadComunication = useCallback(async () => {
    const coms = await communicationApi.communicationGetFirmCommunications({ lowerBound: CommunicationStatus.Pending, upperBound: CommunicationStatus.Complete });
    setCommunications(coms);
  }, [communicationApi]);

  useEffect(() => {
    if (!communications && useBridge) {
      loadComunication();
    }
  }, [communications, useBridge, loadComunication]);
  useEffect(() => {
    if (user) {
      if (!isSignUpModalQuery) {
        const showIntroTutorial = !!user?.preferences &&
          !user.preferences.hasSeenIntroTutorial;
        setIsIntroTutorialModalShow(showIntroTutorial);
      } else {
        handleDismissTutorial();
      }
    }
  }, [user, isSignUpModalQuery, handleDismissTutorial]);

  useEffect(() => {
    if (user && firm) {
      shouldShowSignUpWindow(firm);
    }
  }, [user, firm]);

  useEffect(() => {
    async function load() {
      if (!infoLoading.current && user && !allCases && !allClients) {
        infoLoading.current = true;
        const [cases, clients] = await Promise.all([
          firmApi.firmGetFirmCases({ firmId: user.firmId! }),
          clientApi.clientGetAll({ firmId: user.firmId! })
        ]);
        setAllCases(cases);
        setAllClients(clients);
      }
    }
    load();
  }, [user, allCases, allClients, clientApi, firmApi, caseApi]);

  function shouldShowSignUpWindow(firm: FirmClient) {
    const query = sessionStorage.getItem("showSignUp");
    const queryShouldShowSignUp = !!query &&
      !localStorage.getItem(hasSeenSignUpModalStorageKey) &&
      firm.billingInfo?.subscriptionStatus !== SubscriptionStatus.Active;
    setIsSignUpModalShow(queryShouldShowSignUp);
    setIsSignUpModalQuery(queryShouldShowSignUp);
  }

  async function handleUpload(fileList: FileList) {
    if (user!.isInactive || hasCanceledSubscription(firm!)) {
      showSignupPage();
      return;
    }
    if (showPropoundIntroModal) setShowPropoundIntroModal(false);
    setError("");
    var files = Array.from(fileList);
    if (files.length) {
      if (isQuickActionUploadShow) {
        setIsQuickActionUploadShow(false);
      }
      const sanitizedFiles = sanitizeFiles(files);
      if (sanitizedFiles.length) {
        var newUploadingDocs = sanitizedFiles.map((file) =>
          getUploadingDocument(file.name)
        );
        setUploadingDocuments([...uploadingDocuments, ...newUploadingDocs]);
        await uploadFiles(sanitizedFiles, undefined, isPleadingUpload);
        setUploadingDocuments([
          ...uploadingDocuments.filter((doc) =>
            !newUploadingDocs.includes(doc)
          ),
        ]);


      } else {
        setError("Only .pdf files are currently supported.");
      }
    }
    if (isPleadingUpload) setIsPleadingUpload(false);
  }

  async function handleDropFiles(files: FileList) {
    handleUpload(files);
    setIsOver(false);
  }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      handleUpload(files);
    }
    e.target.value = "";
  }

  function externalUploadRefresh() {
    setShowUploadDocs(false);
  }

  function showSignupPage() {
    rg4js("trackEvent", {
      type: "pageView",
      path: `/signup-modal`,
    });
    setIsSignUpModalShow(true);
  }

  async function handleChoseParty(party: Party): Promise<void> {
    setTypeToGenerate(undefined);
    const caseToPush = quickActionCase!;
    if (!caseToPush.otherParties) {
      caseToPush.otherParties = [];
    }

    const selected = caseToPush.otherParties.findIndex((p) =>
      p.id === party.id
    );

    if (selected === -1) {
      caseToPush.otherParties.push(party);
    } else {
      caseToPush.otherParties[selected] = party;
    }
    const previous = nonArchivedDocs?.filter((x) =>
      x.info?.otherPartyId === party.id &&
      x.info?.documentSubType === typeToGenerate
    );

    previous?.sort((a, b) =>
      (a.info?.setNumber ?? 1) - (b.info?.setNumber ?? 1)
    );
    const highest = previous?.pop();
    const start = (highest?.info?.startingNumber ?? 1) +
      (highest?.interrogatories?.length ?? highest?.info?.interrogatoryCount ??
        0);

    history.push({
      pathname:
        `/clients/${quickActionCase?.clientId ?? quickActionClient?.id}/cases/${quickActionCase?.id}/generateDiscoveryRequest`,
      search: `?type=${typeToGenerate}&partyId=${party.id}&set=${(highest?.info?.setNumber ?? 0) + 1
        }&start=${start}`,
      state: { case: caseToPush, client: quickActionClient },
    });
  }

  async function handleCaseClientCreation(newCaseInfo?: Case) {
    // Case creation
    let newCase = newCaseInfo || quickActionCase!;
    let newClient = quickActionClient!;

    if (isExternalEdit) {
      if (!quickActionClient?.id) {
        try {
          newClient = await clientApi.clientPost({
            firmId: user!.firmId!,
            client: newClient,
          });
        } catch (error) {
          rg4js('send', {
            error: new Error(`There was an issue creating a new client.`),
          });
          throw new Error(`There was an issue creating a new client.`);
        }

        if (quickActionClient?.integration?.identifier) {
          try {
            await clientApi.clientExternalIntegration({ firmId: user!.firmId!, id: newClient.id!, externalIntegration: newClient?.integration })
          } catch (error) {
            rg4js('send', {
              error: new Error(`There was an issue creating a new external client.`),
            });
            throw new Error(`There was an issue creating a new external client.`);
          }
        }

      }

      if (!quickActionCase?.clientId) {
        try {
          await caseApi.caseAddClient({ firmId: user!.firmId!, id: newCase?.id!, body: newClient?.id!, });
        } catch (error) {
          rg4js('send', {
            error: new Error(`There was an issue adding client to the external case.`),
          });
          throw new Error(`There was an issue adding client to the external case.`);
        }
      }

      try {
        await caseApi.casePut({ firmId: user?.firmId!, id: newCase?.id!, _case: newCase });
      } catch (error) {
        rg4js('send', {
          error: new Error(`There was an issue updating the external case.`),
        });
        throw new Error(`There was an issue updating the external case.`);
      }

      await loadTags(setTags, partyTags, tagApi);
      setQuickActionCase(newCase);
      setQuickActionClient(newClient);
      setTypeToGenerate(quickActionType);
      setIsQuickActionModalShow(false);
      setQuickActionIsSaving(false);
      setShowAddNewCase(false);

    } else {
      if (newCaseInfo) {
        try {
          newCase = await caseApi.casePost({
            firmId: user!.firmId!,
            _case: newCase,
          });

          if (newCaseInfo.integration && newCaseInfo.integration.identifier) {
            await caseApi.caseExternalIntegration({
              firmId: user!.firmId!,
              id: newCase?.id!,
              externalIntegration: newCase.integration,
            });
          }

          setQuickActionCase(newCase);
        } catch (error) {
          rg4js('send', {
            error: new Error(`There was an issue creating a new case.`),
          });
          throw new Error(`There was an issue creating a new case.`);
        }
      }
      // Client creation
      if (!quickActionClient?.id) {
        try {
          newClient = await clientApi.clientPost({
            firmId: user!.firmId!,
            client: quickActionClient,
          });

          if (quickActionClient?.integration && quickActionClient.integration.identifier) {
            await clientApi.clientExternalIntegration({ firmId: user!.firmId!, id: newClient.id!, externalIntegration: newClient?.integration })
          }

          setQuickActionClient(newClient);
        } catch (error) {
          rg4js('send', {
            error: new Error(`There was an issue creating a new client.`),
          });
          throw new Error(`There was an issue creating a new client.`);
        }
      }

      try {
        await caseApi.caseAddClient({
          firmId: user!.firmId!,
          id: newCase?.id!,
          body: newClient?.id!,
        });
        await loadTags(setTags, partyTags, tagApi);
        setTypeToGenerate(quickActionType);
        setIsQuickActionModalShow(false);
        setQuickActionIsSaving(false);
        setShowAddNewCase(false);
      } catch (error) {
        rg4js('send', {
          error: new Error(`There was an issue adding client to the case.`),
        });
        throw new Error(`There was an issue adding client to the case.`);
      }
    }


  }

  function exClientFetchSuccess(res: ExternalClientIEnumerableExternalPartnerResponse) {
    const remappedData = remapExternalClientList(res);

    setQuickActionExternalClients(remappedData ?? undefined);
    if (remappedData) {
      setQuickActionClient(remappedData[0] as Client);
    }
  }

  function exClientFetchError() {
    setQuickActionError("There was an error retrieving cases, please try again later.");
    rg4js('send', {
      error: new Error("There was an error fetching external client data."),
    });
    setQuickActionIsSaving(false);
    throw new Error("There was an error fetching external client data.");
  }

  async function fetchExternalClients(externalCase: Case) {
    const res = await externalRequestWrapper(clientApi.clientGetExternal({ firmId: user?.firmId!, externalCaseId: externalCase.integration?.identifier! }), 3);
    handleExternalReqResCodes(res, exClientFetchSuccess, exClientFetchError, initiateAuth);
  }

  async function handleSelectGenerate(value: DocumentSubType): Promise<void> {
    pendingDraftType.current = undefined;
    setQuickActionIsSaving(true);
    // show info page if user has not provided info yet, else generate word document
    if (hasProvidedInfo) {
      if ((!user!.isInactive && isInTrialOrHasActiveSubscription(firm!))) {
        await loadTags(setTags, partyTags, tagApi);
        if ((quickActionCase?.integration?.partner && (quickActionCase.jurisdiction === blankId || quickActionCase.venue === blankId))) {
          setIsExternalEdit(true);
          setIsQuickActionModalShow(false);
          setShowAddNewCase(true);
          return;
        }
        // Client exists, proceeds the flow
        if ((quickActionCase && quickActionCase.id) && (quickActionClient && quickActionClient.id)) {
          setTypeToGenerate(value);
          // Case exists, but its a new client
        } else if ((quickActionCase && quickActionCase.id) && (quickActionClient && !quickActionClient.id)) {
          handleCaseClientCreation();
          // Creates a new case
        }
        else {
          setShowAddNewCase(true);
        }

      } else {
        showSignupPage();
      }
      setIsQuickActionModalShow(false);
      setQuickActionIsSaving(false);
    }
  }

  function renderExternalPartnerActions(toggleOnly?: boolean) {
    return (
      <>
        {<ExternalPartnerConnection />}
        {!toggleOnly && (
          <ExternalPartnerMenu
            user={user}
            externalAction={isQuickActionUploadShow ? handleQuickActionExternalUpload : setShowUploadDocs}
            bpDefault={handleChooseFile}
          />
        )}
      </>
    );
  }

  function handlePropoundModalInit() {
    setShowPropoundIntroModal(true);
  }

  function handlePropoundModalDismiss() {
    setShowPropoundIntroModal(false);
    setIsPleadingUpload(false);
  }

  function handleQuickActionModal() {
    if (!hasProvidedInfo) {
      setShowInfoModal(true);
    }

    if (hasProvidedInfo && (!user!.isInactive && isInTrialOrHasActiveSubscription(firm!))) {
      setIsQuickActionModalShow(true);
    } else {
      showSignupPage();
    }
  }

  function handleQuickActionUpload() {
    setIsQuickActionUploadShow(true);
  }

  function handleQuickActionExternalUpload() {
    handleQuickActionDismiss();
    setShowUploadDocs(true);
  }

  function handleQuickActionDismiss() {
    setIsQuickActionModalShow(false);
    setIsQuickActionUploadShow(false);
    setIsExternalEdit(false);
    setQuickActionCase(undefined);
    setQuickActionClient(undefined);
    setQuickActionExternalClients(undefined);
    setQuickActionType(undefined);
    setQuickActionIsSaving(false);
    setTags(undefined);
    setTypeToGenerate(undefined);
    setShowAddNewCase(false);
    setIsPleadingUpload(false);
  }

  function handleDismissSignUp() {
    localStorage.setItem(hasSeenSignUpModalStorageKey, "true");
    setIsSignUpModalShow(false);
  }

  async function handleRemoveClientAccess(comunication: ViewCommunication) {
    await communicationApi.communicationUpdateCommunication({
      id: comunication.id!, putClientCommunication: {
        ...comunication,
        sharedWithObjectType: undefined,
        sharedWithObjectId: undefined,
        status: CommunicationStatus.Closed
      }
    });
    setCommunications(communications?.filter(c => c.id !== comunication.id));
  }


  async function handleUpdateCommunication(communication: ViewCommunication) {
    const resp = await communicationApi.communicationUpdateCommunication({
      id: communication.id!,
      putClientCommunication: { ...communication }
    });

    setCommunications(communications?.map(comm =>
      comm.id === communication.id
        ? { ...resp, externalCommunicationItems: comm.externalCommunicationItems }
        : comm
    ));
  }

  async function handleArchiveDocument(doc: DocumentClient) {
    if (useBridge) {
      const commsToArchive = communications?.filter(c => c.objectId === doc.id && c.status !== CommunicationStatus.Closed) ?? [];
      for (const com of commsToArchive) {
        await communicationApi.communicationUpdateCommunication({
          id: com.id!, putClientCommunication: {
            ...com,
            status: CommunicationStatus.Closed
          }
        });
        setCommunications(communications?.filter(c => c.id !== com.id));
      }
    }

    await archiveDocument(doc);
  }

  const trialEndsMessage = GetTrialBannerMessage(user, firm);
  const showCountdownStyle = firm?.billingInfo?.creditsExpireDate
    ? true
    : false;
  const trialEnds = (
    <TrialEndsBanner
      onClickSignUp={() => showSignupPage()}
      message={trialEndsMessage}
      showCountdownStyle={showCountdownStyle}
    />
  );

  return (
    <Container className="page-container">
      {/* <Alert variant="danger">Briefpoint is currently experiencing a service outage preventing newly uploaded documents from processing, we apologize for the inconvenience and will remove this banner when the issue is resolved.</Alert> */}
      {trialEnds}
      {error && <Alert variant="danger">{error}</Alert>}
      {didSignUp && <Alert variant="success">Success! Signup complete.</Alert>}
      {useRibbon && <TopRibbon handleQuickActionUpload={handleQuickActionUpload} handlePropoundIntroModal={handlePropoundModalInit} handleQuickActionModal={handleQuickActionModal} />}
      {uploadingDocuments && uploadingDocuments.length > 0 && uploadingDocuments.map((doc) => (
        <ProcessingBox
          key={doc.id}
          document={doc}
          archiveDocument={archiveDocument}
          processingType="uploading"
          estimatedProgress={0}
          setEstimatedProgress={noOp}
        />
      ))}
      {processingDocs && processingDocs.length > 0 && processingDocs.map((doc) => (
        <ProcessingBox
          key={doc.id}
          document={doc}
          archiveDocument={archiveDocument}
          estimatedProgress={
            //estimatedProgress == parsingProgress * 100
            (estimatedProgress[doc.id] ?? 0) >
              (doc.info?.parsingProgress ??
                (doc.status === DocumentStatus.Processed ? 1 : 0) ??
                0) * 100
              ? estimatedProgress[doc.id]
              : (doc.info?.parsingProgress ??
                (doc.status === DocumentStatus.Processed ? 1 : 0) ?? 0) *
              100
          }
          setEstimatedProgress={setProgress}
        />
      ))}
      {!recentComms?.length && <header className="d-flex justify-content-end align-items-center">
        <div className={`${hasDocuments ? 'document-actions' : 'document-actions mb-3'}`}>
          {renderExternalPartnerActions()}
          <Help
            id="document-help"
            text={`Briefpoint currently accepts the following state court documents: <ul><li class="tooltip-row">Requests for Admission</li><li class="tooltip-row">Requests for Production</li><li class="tooltip-row">${supportsFrogs ? "Special " : ""}Interrogatories</li>${supportsFrogs
              ? '<li class="tooltip-row">Form Interrogatories</li>'
              : ""
              }</ul>`}
            direction={"bottom"}
          />
        </div>
        <input type="file" id="file-input" name="file" ref={fileInputRef} onChange={handleFileChange} className="d-none" multiple />
      </header>}
      {/* <h2 className="mt-5">Recent Documents</h2> */}
      {/* <hr /> */}
      <Loading className='parentDocList' isLoading={documents === null || (useBridge && communications === null)}>
        {recentComms?.length ? <>
          <header className="d-flex justify-content-between align-items-center mb-3 mt-3">
            <h4 className="table-headers">Recent Client Activity</h4>
            <div className={`${hasDocuments ? 'document-actions' : 'document-actions mb-3'}`}>
              {renderExternalPartnerActions()}
              <Help
                id="document-help"
                text={`Briefpoint currently accepts the following state court documents: <ul><li class="tooltip-row">Requests for Admission</li><li class="tooltip-row">Requests for Production</li><li class="tooltip-row">${supportsFrogs ? "Special " : ""}Interrogatories</li>${supportsFrogs
                  ? '<li class="tooltip-row">Form Interrogatories</li>'
                  : ""
                  }</ul>`}
                direction={"bottom"}
              />
            </div>
            <input type="file" id="file-input" name="file" ref={fileInputRef} onChange={handleFileChange} className="d-none" multiple />
          </header>
          <DocumentList documents={recentComms!} archiveDocument={handleArchiveDocument} updateDocument={updateDocCaseClient} allCases={allCases} allClients={allClients} downloadForm={downloadForm} downloadRequest={downloadRequest} externalCommunications={communications} removeClientAccess={handleRemoveClientAccess} updateCommunication={handleUpdateCommunication} useBridge={useBridge} />
        </> : ''}
        <div className={classes('documents-wrapper', { dropping: isOver })} onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave}>
          {hasDocuments ? (
            <>
              <h4 className="table-headers">Document List</h4>
              <DocumentList documents={tempConfirmedDocs!} archiveDocument={handleArchiveDocument} updateDocument={updateDocCaseClient} allCases={allCases} allClients={allClients} downloadForm={downloadForm} downloadRequest={downloadRequest} externalCommunications={communications} removeClientAccess={handleRemoveClientAccess} updateCommunication={handleUpdateCommunication} useBridge={useBridge} />
            </>
          ) : (
            <EmptyList chooseFile={handleChooseFile} supportsFrogs={getFirmJx?.id === CaliforniaId} />
          )}
          <p className='archive'><Link to="/documents/archive">View Archived Documents</Link></p>
        </div>
      </Loading>
      {(isQuickActionModalShow || isQuickActionUploadShow) && <QuickActionModal
        quickActionClient={quickActionClient}
        setQuickActionClient={setQuickActionClient}
        quickActionCase={quickActionCase}
        setQuickActionCase={setQuickActionCase}
        quickActionType={quickActionType}
        setQuickActionType={setQuickActionType}
        quickActionIsSaving={quickActionIsSaving}
        isExternalConnectionActive={user?.externalConnection?.isActive}
        cases={allCases}
        clients={allClients}
        quickActionExternalClients={quickActionExternalClients}
        renderExternalPartnerActions={renderExternalPartnerActions}
        isShow={isQuickActionModalShow || isQuickActionUploadShow}
        uploadOnly={isQuickActionUploadShow}
        formError={quickActionError}
        setFormError={setQuickActionError}
        fetchExternalClients={fetchExternalClients}
        handleChooseFile={handleChooseFile}
        handleQuickActionExternalUpload={handleQuickActionExternalUpload}
        getFirmJx={getFirmJx}
        onClose={handleQuickActionDismiss}
        onConfirm={handleSelectGenerate}
      />}
      {quickActionCase && jurisdictions && missingRequiredInfo && (
        <RequiredCaseInfo
          show={!!typeToGenerate && missingRequiredInfo}
          onClose={handleQuickActionDismiss}
          onConfirm={handleRequiredCase}
          _case={quickActionCase}
          jurisdictions={jurisdictions}
        />
      )}
      {quickActionCase && tags && jurisdiction && (
        <SelectPartyModal
          availableTags={filterTagsForParties(
            tags,
            quickActionCase,
            partyTags.current,
          )}
          show={!!typeToGenerate && !missingRequiredInfo}
          type={typeToGenerate}
          onClose={handleQuickActionDismiss}
          onConfirm={handleChoseParty}
          jurisdiction={jurisdiction}
          _case={quickActionCase}
        />
      )}
      {trialEndsMessage && (
        <SignUpModal isShow={isSignUpModalShow} onClose={handleDismissSignUp} />
      )}
      {showAddNewCase && <AddEditCaseModal
        title="Add Case"
        confirm="Add"
        availableTags={tags}
        setAvailableTags={setTags}
        isShow={showAddNewCase}
        users={users}
        jurisdictions={jurisdictions!}
        _case={quickActionCase}
        firm={firm!}
        newCaseTitle={quickActionCase?.shortTitle ?? ''}
        onClose={handleQuickActionDismiss}
        onConfirm={handleCaseClientCreation}
      />}
      <IntroTutorialModal
        isShow={isIntroTutorialModalShow}
        supportsFrogs={supportsFrogs}
        onClose={handleDismissTutorial}
        fileUpload={handleChooseFile}
      />
      {showPropoundIntroModal &&
        <PropoundSelectionModal
          isShow={showPropoundIntroModal}
          onClose={handlePropoundModalDismiss}
          handleQuickActionModal={handleQuickActionModal}
          handleQuickActionUpload={handleChooseFile}
          setShowPropoundIntroModal={setShowPropoundIntroModal}
          setIsPleadingUpload={setIsPleadingUpload}
        />
      }
      {!hasProvidedInfo && <Modal
        show={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        size="xl"
        title={"Information for output document"}
        showCancelButton={false}
        showConfirmButton={false}
        preventBackgroundClick>
        <GeneralInformationScreen
          documentId={"anything"}
          onSave={async () => {
            setShowInfoModal(false);
            setIsQuickActionModalShow(true);
          }}
        />
      </Modal>}
      {user?.externalConnection && (
        <Modal
          onClose={() => setShowUploadDocs(false)}
          className="externalModal"
          show={showUploadDocs}
          title="Upload Files"
          confirmText="Upload Files"
        >
          <ExternalFileBrowserSelect
            cases={allCases}
            setIsExternalUploading={setIsExternalUploading}
            isExternalUploading={isExternalUploading}
            loadDocuments={loadDocuments}
            onFinish={externalUploadRefresh}
          />
        </Modal>
      )}
    </Container>
  );
}
