import { useCallback, useEffect, useState } from 'react';
import { useContentApi } from './useApi';
import { DocumentType, MiscContent, MiscContentType } from 'briefpoint-client';

type MiscContentProps = [
  MiscContent[] | null,
  MiscContent | null,
  MiscContent[] | null,
  MiscContent | null,
  MiscContent[] | null,
  (update: MiscContent) => Promise<MiscContent>,
  (update: MiscContent) => Promise<MiscContent>,
  (update: MiscContent[]) => Promise<void>,
];
export default function useMiscContent(loadContent: boolean = true): MiscContentProps {
  const contentApi = useContentApi();
  const [allPreliminaryStatements, setAllPreliminaryStatements] = useState<MiscContent[] | null>(null);
  const [generalObjections, setGeneralObjections] = useState<MiscContent | null>(null);
  const [intros, setAllIntros] = useState<MiscContent[] | null>(null);
  const [instructions,] = useState<MiscContent | null>(null);
  const [questions, setQuestions] = useState<MiscContent[] | null>(null);

  const loadAllPreliminaryStatements = useCallback(async () => {
    const statements = await contentApi.contentGetAllMiscContent({ type: MiscContentType.PreliminaryStatement });
    setAllPreliminaryStatements(statements);
  }, [contentApi]);

  const loadGeneralObjections = useCallback(async () => {
    const objections = await contentApi.contentGetMiscContent({ type: MiscContentType.GeneralObjections });
    setGeneralObjections(objections);
  }, [contentApi]);

  const loadAllIntros = useCallback(async () => {
    const i = await contentApi.contentGetAllMiscContent({ documentType: DocumentType.DiscoveryRequest, type: MiscContentType.PreliminaryStatement });
    setAllIntros(i);
  }, [contentApi]);

  const loadInstructions = useCallback(async () => {
    // const instr = await contentApi.contentGetMiscContent({ documentType: DocumentType.DiscoveryRequest, type: MiscContentType.GeneralObjections });
    // setInstructions(instr);
  }, []);

  const loadQuestions = useCallback(async () => {
    const qs = await contentApi.contentGetAllMiscContent({ documentType: DocumentType.DiscoveryRequest, type: MiscContentType.DiscoveryQuestion });
    setQuestions(qs);
  }, [contentApi]);

  async function updateMiscContent(update: MiscContent) {
    const result = await contentApi.contentPutMiscContent({ miscContent: update });

    const updateOrAdd = (prev: MiscContent[] | null, newItem: MiscContent) => {
      if (prev === null) {
        return [newItem];
      }
      const exists = prev.some((x) => x.id === newItem.id);
      return exists ? prev.map((x) => (x.id === newItem.id ? newItem : x)) : [...(prev || []), newItem];
    };

    if (result.type === MiscContentType.PreliminaryStatement) {
      if (result.documentType === DocumentType.DiscoveryRequest) {
        setAllIntros((prev) => updateOrAdd(prev, result));
      } else {
        setAllPreliminaryStatements((prev) => updateOrAdd(prev, result));
      }
    } else if (result.type === MiscContentType.GeneralObjections) {
      setGeneralObjections(result);
    } else if (result.type === MiscContentType.DiscoveryQuestion) {
      setQuestions((prev) => updateOrAdd(prev, result));
    }

    return result;
  }

  async function createMiscContent(miscContent: MiscContent) {
    const result = await contentApi.contentPostMiscContent({ miscContent });
    return result;
  }

  async function createMiscContentMultiple(miscContent: MiscContent[]) {
    const result = await contentApi.contentPostMultipleMiscContent({ miscContent });
    return result;
  }

  useEffect(() => {
    if (loadContent) {
      loadGeneralObjections();
      loadAllPreliminaryStatements();
      loadAllIntros();
      loadInstructions();
      loadQuestions();
    }
  }, [loadGeneralObjections, loadAllPreliminaryStatements, loadAllIntros, loadInstructions, loadQuestions, loadContent]);

  return [allPreliminaryStatements, generalObjections, intros, instructions, questions, updateMiscContent, createMiscContent, createMiscContentMultiple];
}
