import { ExternalPartners } from 'briefpoint-client';
import { BaseSelect } from 'components/Select/Select';
import Toggle from 'components/Toggle';
import { useFirmApi, useUserApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useConfig, { ExternalPartnerConfig } from 'hooks/useConfig';
import { useMemo, useState } from 'react';
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { PopupNavigator } from './AuthPopup/PopupNavigator';
import { UrlUtils } from './AuthPopup/UrlUtils';

const REACT_APP_BASE_URL: string =
  (window as any)._env_?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

// This should probably be derived from configuration, but Smokeball and Clio only have a prod environment so ... :shrug:
export const GetExternalPartnerAuthUrl = (
  partner: ExternalPartners,
  userId: string,
  configStore: (partner: ExternalPartners) => ExternalPartnerConfig
) => {
  const state = { userId: userId, partner: partner };
  const returnAuthority = REACT_APP_BASE_URL.split(';')[0];
  const returnUrl = `${returnAuthority}`;

  switch (partner) {
    case ExternalPartners.Smokeball:
      return `https://auth.smokeball.com/oauth2/authorize?response_type=code&client_id=25192rib3rkb067a4isvd7ko1i&redirect_uri=${encodeURIComponent(
        returnUrl
      )}&state=${encodeURIComponent(btoa(JSON.stringify(state)))}`;
    case ExternalPartners.Clio:
      const clioConfig = configStore(ExternalPartners.Clio);
      return `https://app.clio.com/oauth/authorize?response_type=code&client_id=${
        clioConfig.clientId
      }&redirect_uri=${encodeURIComponent(returnUrl)}&state=${encodeURIComponent(
        btoa(JSON.stringify(state))
      )}`;
    case ExternalPartners.MyCase:
      const config = configStore(ExternalPartners.MyCase);
      return `${config.authBase}/login_sessions/new?response_type=code&client_id=${
        config.clientId
      }&redirect_uri=${encodeURIComponent(returnUrl)}&state=${encodeURIComponent(
        btoa(JSON.stringify(state))
      )}`;
    default:
      return null;
  }
};

export default function ExternalPartnerConnection() {
  const { user, setUser, firm } = useAuth()!;
  const [connecting, setConnecting] = useState(false);
  const [alert, setAlert] = useState<string | undefined>();
  const userApi = useUserApi();
  const firmApi = useFirmApi();
  const [config] = useConfig();

  const connectionIsActive = user?.externalConnection?.isActive ?? false;
  const [firmPartner, setFirmPartner] = useState<ExternalPartners | null>(
    firm?.preferences?.enabledExternalProviders?.values().next().value ?? null
  );
  const [selectedPartner, setSelectedPartner] = useState<ExternalPartners | null>(firmPartner);

  const partnerOptions = useMemo(() => {
    return Object.values(ExternalPartners)
      .filter((x) => x !== ExternalPartners.None)
      .sort()
      .map((partner) => ({
        label: partner,
        value: partner,
        icon: <PartnerLogo partner={partner} width={14} />,
      }));
  }, []);

  if (!user || !firm) {
    return null;
  }

  const onToggle = async () => {
    if (selectedPartner) {
      setConnecting(true);
      setAlert(undefined);
      const authUrl = GetExternalPartnerAuthUrl(selectedPartner, user.id!, config);

      if (!user?.externalConnection?.isActive) {
        const popupNavigator = new PopupNavigator();
        const handle = await popupNavigator.prepare({});

        try {
          const result = await handle.navigate({
            url: authUrl!,
          });

          const params = UrlUtils.readParams(result.url);
          const code = params.get('code');
          if (code) {
            const tokenResult = await userApi.userExternalPartnerAuth({
              userId: user.id!,
              code,
              partner: selectedPartner,
            });
            if (tokenResult.responseCode !== 200) {
              throw new Error(
                `Unable to authenticate to ${selectedPartner}. Status Code: ${tokenResult.responseCode}`
              );
            }

            setUser((u) => {
              const newUser = { ...u };
              newUser.externalConnection = { partner: selectedPartner, isActive: true };
              return newUser;
            });

            await firmApi.firmUpdatePreferences({
              id: firm.id,
              firmPreferences: { enabledExternalProviders: new Set([selectedPartner]) },
            });

            setFirmPartner(selectedPartner);
          } else {
            throw new Error(`Unexpected response from auth: ${params}`);
          }
        } catch (_e) {
          let message = '';
          if (typeof _e === 'string') {
            message = _e;
          } else if (_e instanceof Error) {
            message = _e.message;
          }

          if (message.includes('closed by user')) {
            setAlert('Authentication cancelled');
          }
        }
      } else {
        setUser((u) => {
          const curUser = { ...u };
          curUser.externalConnection = {};
          return curUser;
        });
      }

      setConnecting(false);
    }
  };

  const getLabel = () => {
    if (connecting) return 'Connecting...';
    return (
      <span>
        {connectionIsActive ? 'Connected to' : 'Connect to'}
        {firmPartner && (
          <>
            <PartnerLogo className="mx-1" partner={firmPartner} width={14} />
            {firmPartner}
          </>
        )}
      </span>
    );
  };

  return (
    <div className="d-flex gap-2">
      <Toggle
        checked={connectionIsActive}
        checkedName=""
        unCheckedName=""
        label={getLabel()}
        alert={alert}
        onToggle={onToggle}
        disabled={connecting || !selectedPartner}
      />
      {!firmPartner && !connecting && (
        <BaseSelect
          onChange={(selectedOption) => setSelectedPartner(selectedOption?.value as ExternalPartners)}
          placeholder="Select system"
          options={partnerOptions}
        />
      )}
    </div>
  );
}

const PartnerLogo = ({
  partner,
  ...props
}: { partner: ExternalPartners } & React.SVGProps<SVGSVGElement>) => {
  switch (partner) {
    case ExternalPartners.Clio:
      return <ClioLogo title={partner} {...props} />;
    case ExternalPartners.Smokeball:
      return <SmokeballLogo title={partner} {...props} />;
    case ExternalPartners.MyCase:
      return <MyCaseLogo title={partner} {...props} />;
    default:
      return null;
  }
};
