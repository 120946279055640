/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOfficeModel
 */
export interface CreateOfficeModel {
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeModel
     */
    jurisdictionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeModel
     */
    state?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeModel
     */
    includeFederal?: boolean;
}

export function CreateOfficeModelFromJSON(json: any): CreateOfficeModel {
    return CreateOfficeModelFromJSONTyped(json, false);
}

export function CreateOfficeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOfficeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'includeFederal': !exists(json, 'includeFederal') ? undefined : json['includeFederal'],
    };
}

export function CreateOfficeModelToJSON(value?: CreateOfficeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdictionId': value.jurisdictionId,
        'state': value.state,
        'includeFederal': value.includeFederal,
    };
}


