import { useMemo } from 'react';
import { Jurisdiction, Venue } from 'briefpoint-client';
import ReactSelect from 'react-select';

interface Props {
  setVenue: (value: string) => void;
  venue: string;
  federalJurisdiction: Jurisdiction | undefined;
  jurisdictions: Jurisdiction[] | undefined;
  officeJxIds?: string[];
}

export const customSelectStyles = {
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderColor: 'rgb(128, 135, 136)',
    boxShadow: !state.isFocused ? '0' : '0 0 0 0.25rem rgba(4, 65, 84, 0.25)',
    "&:hover": {
      borderColor: 'rgb(128, 135, 136)'
    },
    fontSize: '16px'
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 3 //some stuff in the sdr flow was appearing on top
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    color: '#4D5758',
    '& svg': {
      width: 22,
      paddingRight: "4px",
    },
    '&:hover': {
      color: '#4D5758',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '2px 8px'
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
  }),
  group: (provided: any, state: any) => ({
    ...provided,
    padding: '0px',
  }),
  groupHeading: (provided: any, state: any) => {
    if (!state.data.key) {
      return provided;
    }

    if (!state.id.includes('group-0')) {

      return {
        ...provided,
        fontWeight: '700',
        borderBottom: '1pt solid #cccccc',
        borderTop: '1pt solid #cccccc',
      }
    } else {
      return {
        ...provided,
        fontWeight: '700',
        borderBottom: '1pt solid #cccccc',
      }
    }
  },
};

export default function FederalSelect(props: Props) {
  const { setVenue, venue, federalJurisdiction, jurisdictions, officeJxIds } = props;

  const getOptions = useMemo(() => {

    const sortVenues = (a: Venue, b: Venue) => {
      if (officeJxIds) {
        var aCon = officeJxIds.includes(a.relatedJurisdictionId ?? "");
        var bCon = officeJxIds.includes(b.relatedJurisdictionId ?? "");

        if (aCon && !bCon) {
          return -1;
        }
        else if (bCon && !aCon) {
          return 1;
        }
      }
      var aJxName = mapIdToJxName(a.relatedJurisdictionId ?? "") ?? "";
      var bJxName = mapIdToJxName(b.relatedJurisdictionId ?? "") ?? "";
      var lcJx = aJxName?.localeCompare(bJxName);

      if (lcJx !== 0) {
        return lcJx;
      }

      return a.shortName?.localeCompare(b.shortName ?? "") ?? 0;
    }

    const mapIdToJxName = (jxId: string) => {
      if (!jurisdictions) {
        return "";
      }

      var jx = jurisdictions.find(j => j.id === jxId);
      return jx?.friendlyName?.toUpperCase();
    }

    function unique(value: any, index: any, array: string | any[]) {
      return array.indexOf(value) === index;
    }

    if (!jurisdictions) {
      return;
    }
    if (!federalJurisdiction || !federalJurisdiction.venues) {
      return;
    }

    var relJXIds = federalJurisdiction.venues.flatMap(r => r!.relatedJurisdictionId).filter(unique) as string[];
    var sortedResults = federalJurisdiction.venues.sort(sortVenues);

    const opts: any[] = []

    relJXIds.forEach((group: string) => {
      const groupResults = sortedResults.filter(r => r.relatedJurisdictionId === group);
      opts.push({ key: mapIdToJxName(group), options: groupResults.map(v => { return { label: v.shortName, value: v.id } }) })
    })

    return opts;
  }, [federalJurisdiction, jurisdictions, officeJxIds]);

  const handleChange = (option: any) => {
    if (!option || !option.value) {
      return;
    }

    setVenue(option.value);
  };

  const formatGroupLabel = (option: any) => {
    return (
      <div>{option.key}</div>
    );
  };

  const findOptionByVenueId = (options: any, venueId: string) => {
    for (const group of options) {
      const found = group.options.find((option: { label: any; value: string }) => option.value === venueId);
      if (found){
        return found;
      } 
    }
    return null;
  };
  
  return (
    <ReactSelect
      className='select'
      id="venue"
      options={getOptions}
      styles={customSelectStyles}
      value={findOptionByVenueId(getOptions, venue)}
      onChange={handleChange}
      formatGroupLabel={formatGroupLabel}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#074f6b',
          primary25: '#efefea',
        },
      })}
      placeholder='Required please select...'
      menuPlacement='auto'
    ></ReactSelect>
  );
}
