import { Case, Client, ContentGetMiscContentFilteredRequest, Definition, DocumentType, Firm, Jurisdiction, MiscContentType, PartyPosition, Representative, User } from "briefpoint-client";
import { FormModal } from "components/Modals";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Form, Nav, Tab } from "react-bootstrap";
import './AddEditCaseModal.scss';
import Attorneys from "./Attorneys";
import CaseTypeSelect, { CaseTypeOption } from "./CaseTypeSelect";
import { TagOption } from "components/Select/TagsSelect";
import { OptionsType } from "react-select";
import Loading from "components/Loading";
import { useContentApi, useDefinitionsApi, useTagApi } from "hooks/useApi";
import { ArrowCounterclockwise } from "react-bootstrap-icons";
import SelectCreate from "components/Select/TagsSelectCreate";
import { SortVenue } from "screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils";
import { Typeahead } from "react-bootstrap-typeahead";
import { blankId } from "components/ConfirmInfo/SourceDocumentReview";
import FederalSelect from "components/FederalSelect";

export const CaliforniaId = "7c635e83-3d84-4b75-a09f-7acbe6c07bf8";
export const FederalId = "6652fce2-272b-4c70-b4a8-9b84642a465f";

interface Props {
  title: string;
  confirm: string;
  isShow: boolean;
  _case?: Case;
  newCaseTitle?: string;
  client?: Client;
  firm: Firm;
  availableTags: Map<number, string> | undefined;
  setAvailableTags: Dispatch<SetStateAction<Map<number, string> | undefined>>;
  users: User[] | null;
  jurisdictions: Jurisdiction[];
  onClose: () => void;
  onConfirm: (_case: Case) => Promise<void>;
}

export default function AddEditCaseModal({ title, confirm, isShow, _case, newCaseTitle, client, firm, availableTags, setAvailableTags, users, jurisdictions, onClose, onConfirm }: Props) {
  const [shortTitle, setShortTitle] = useState((newCaseTitle || _case?.shortTitle) ?? '');
  const [fullTitle, setFullTitle] = useState(_case?.title ?? '');
  const [caseNumber, setCaseNumber] = useState(_case?.caseNumber ?? '');
  const [judge, setJudge] = useState(_case?.judge ?? '');
  const [plaintiff, setPlaintiff] = useState(_case?.plaintiffs ?? '');
  const [defendant, setDefendant] = useState(_case?.defendants ?? '');
  const [billingCode, setBillingCode] = useState<string | undefined>(_case?.billingCode ?? '');
  const [venue, setVenue] = useState((_case?.venue && _case?.venue !== blankId) ? _case?.venue : '');
  const [jurisdictionId, setJurisdictionId] = useState((_case?.jurisdiction && _case?.jurisdiction !== blankId) ? _case?.jurisdiction : undefined);
  const [position, setPosition] = useState(_case?.clientPosition || PartyPosition.Unknown);
  const [representative, setRepresentative] = useState(_case?.representativeType || Representative.Individual);
  const [caseType, setCaseType] = useState(_case?.caseType ?? '');
  const [trialDate, setTrialDate] = useState(_case?.trialDate ?? '');
  const [filedDate, setFiledDate] = useState(_case?.filedDate ?? '');
  const [contactFirst, setContactFirst] = useState(_case?.contactFirstName ?? '');
  const [contactLast, setContactLast] = useState(_case?.contactLastName ?? '');
  const [contactEmail, setContactEmail] = useState(_case?.contactEmail ?? '');
  const [attorneys, setAttorneys] = useState(_case?.attorneys ?? []);
  const [tags, setTags] = useState(_case?.tagIds || []);
  const [addingTags, setAddingTags] = useState(new Map<number, string>());
  const [showAlert, setShowAlert] = useState(true);
  const [showContactOverride, setShowContactOverride] = useState(!!_case?.contactFirstName || !!_case?.contactLastName || !!_case?.contactEmail);
  const [saving, setSaving] = useState(false);
  const [definitions, setDefinitions] = useState<Definition[]>();
  const [deletingDefinitions, setDeletingDefinitions] = useState<Definition[]>([]);
  const [extraTagInfo, setExtraTagInfo] = useState<Map<number, number> | undefined>();
  const definitionsApi = useDefinitionsApi();
  const contentApi = useContentApi();
  const tagApi = useTagApi();
  const typeaheadRef = useRef<any>();

  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const isJsxBlank = _case?.jurisdiction === blankId;
    const firmJx = firm.offices?.find(x => x.jurisdictions?.length)?.jurisdictions![0]?.jurisdictionId;

    if (isJsxBlank) {
      return jurisdictions.find(j => j.id === firmJx);
    } else {
      return jurisdictions?.find(j => j.id === jurisdictionId) ?? jurisdictions?.find(j => j.id === firmJx);
    }

  }, [jurisdictions, firm, _case?.jurisdiction, jurisdictionId]);

  const caseTypes = jurisdiction?.caseClasses?.filter(c => c.subTypes).flatMap(c => [c.subTypes!]).flat();

  const contractOverrideOpen = showContactOverride || contactFirst || contactLast || contactEmail;

  async function doSave() {
    setSaving(true);
    const toSave = _case ? { ..._case } : {};
    toSave.shortTitle = shortTitle;
    toSave.title = fullTitle;
    toSave.caseNumber = caseNumber;
    toSave.judge = judge;
    toSave.plaintiffs = plaintiff;
    toSave.defendants = defendant;
    toSave.jurisdiction = jurisdiction?.id!;
    toSave.billingCode = billingCode;
    toSave.venue = venue;
    toSave.clientPosition = position;
    toSave.representativeType = representative;
    toSave.caseType = caseType === `` ? undefined : caseType;
    toSave.trialDate = trialDate;
    toSave.filedDate = filedDate;
    toSave.contactFirstName = showContactOverride ? contactFirst : undefined;
    toSave.contactLastName = showContactOverride ? contactLast : undefined;
    toSave.contactEmail = showContactOverride ? contactEmail : undefined;
    toSave.attorneys = attorneys;
    toSave.lastActivity = new Date(Date.now()); //not passed to the back end, used to update the local model to display recently updated

    const tagsUpdate = [...tags].filter(x => x > 0);
    const tagsToAdd = Array.from(addingTags.entries());
    for (let i = 0; i < tagsToAdd.length; i++) {
      const t = tagsToAdd[i];
      const newTag = await tagApi.tagCreateTag({ tagCreateRequest: { tagName: t[1] } });
      if (newTag) {
        tagsUpdate.push(newTag.id);
      }
      setAvailableTags(q => {
        const update = q ? new Map([...q]) : new Map<number, string>();
        update.set(newTag.id, newTag.name);

        return update;
      });
    }
    setTags(tagsUpdate);
    setAddingTags(new Map<number, string>());
    toSave.tagIds = tagsUpdate;
    await doDeleteDefinitions();
    await onConfirm(toSave);
    setSaving(false);
  }

  function reset() {
    setShortTitle(_case?.shortTitle ?? '');
    setFullTitle(_case?.title ?? '');
    setCaseNumber(_case?.caseNumber ?? '');
    setJudge(_case?.judge ?? '');
    setPlaintiff(_case?.plaintiffs ?? '');
    setDefendant(_case?.defendants ?? '');
    setShowContactOverride(false);
    setJurisdictionId(undefined);
    setVenue(_case?.venue ?? '');
    setPosition(_case?.clientPosition ?? PartyPosition.Unknown);
    setRepresentative(_case?.representativeType ?? Representative.None);
    setCaseType(_case?.caseType ?? '');
    setFiledDate(_case?.filedDate ?? '');
    setTrialDate(_case?.trialDate ?? '');
    setContactFirst(_case?.contactFirstName ?? '');
    setContactLast(_case?.contactLastName ?? '');
    setContactEmail(_case?.contactEmail ?? '');
    setAttorneys(_case?.attorneys ?? []);
    setBillingCode(_case?.billingCode ?? undefined);
    setTags(_case?.tagIds ?? []);
    setDefinitions(undefined);
    setDeletingDefinitions([]);
    setAddingTags(new Map<number, string>());
  }

  useEffect(() => {
    const query: ContentGetMiscContentFilteredRequest = {
      type: MiscContentType.DiscoveryQuestion,
      documentType: DocumentType.DiscoveryRequest,
      caseType: caseType ?? undefined,
      skipTagFiltering: true
    };
    const extras = new Map<number, number>();
    Promise.all([contentApi.contentGetMiscContentFiltered(query), contentApi.contentGetDefaultMiscContentFiltered(query)])
      .then(([update, defaultQuestions]) => {
        update.flatMap(x => x.tagIds ?? []).forEach(x => {
          const found = extras.get(x);
          extras.set(x, found ? found + 1 : 1)
        });

        defaultQuestions.flatMap(x => x.tagIds ?? []).forEach(x => {
          const found = extras.get(x);
          extras.set(x, found ? found + 1 : 1)
        });
        setExtraTagInfo(extras);
      });
  }, [caseType, contentApi]);

  function doClose() {
    setTimeout(reset, 500);

    onClose();
  }

  function unsetContactInfo(set: boolean) {
    setContactFirst('');
    setContactLast('');
    setContactEmail('');
    setShowContactOverride(set);
  }

  async function doDeleteDefinitions() {
    deletingDefinitions.forEach(async d => {
      d.isDeleted = true;
      await definitionsApi.definitionUpdate({ definition: d });
    })
    setDeletingDefinitions([]);
  }

  useEffect(() => {
    if (newCaseTitle) {
      setShortTitle(newCaseTitle);
    }
  }, [newCaseTitle])


  useEffect(() => {
    const getDefinitions = async () => {
      if (_case?.id) {
        setDefinitions((await definitionsApi.definitionGetCaseList({ caseId: _case?.id })).filter(x => !x.isDeleted && x.isInternal));
      }
    };
    if (!definitions) {
      getDefinitions();
    }
  }, [_case?.id, definitionsApi, definitions]);

  const options: CaseTypeOption[] = useMemo(
    () => {
      if (!caseTypes || !jurisdiction?.caseClasses) {
        return [];
      }
      let vs = caseTypes?.flatMap((v) =>
        [
          {
            value: v.id!,
            label: v.shortName!,
            key: jurisdiction.caseClasses!.find(x => x.id === v.caseClassId)?.name ?? ""
          },
        ]
      );
      return vs;
    },
    [caseTypes, jurisdiction?.caseClasses]
  );

  function handleCaseTypeChange(caseType: string) {
    setCaseType(caseType);
  }

  function handleTagChange(value: OptionsType<TagOption>) {
    setTags(value?.map(x => x.value!) ?? []);
    setAddingTags(q => {
      const update = new Map([...q].filter(a => [...value.values()].find(v => v.value === a[0])));

      return update;
    });
  }

  // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
  // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
  const positionValues = position.split(',').map(item => item.trim());
  function flipPosition(val: PartyPosition) {
    var existing = positionValues.indexOf(val);

    if (existing !== -1) {
      positionValues.splice(existing, 1);
    } else {
      positionValues.push(val);
    }

    let positionVal = positionValues.join();
    if (positionVal.length && positionVal[0] === ",") {
      positionVal = positionVal.substring(1);
    }
    setPosition((positionVal || "Unknown") as PartyPosition);
  }

  function handleDefinitionChange(value: string, index: number, field: string) {
    setDefinitions(ds => {
      const update = [...ds!];
      const item = update[index] as any;
      item[field] = value;
      return update;
    });
  }

  // TODO: retries/error handling
  async function handleDefinitionBlur(index: number) {
    let definition = definitions![index];
    if (!definition.content) return;
    // New or edited definitions are always for the Case
    // Update
    if (definition.id && definition.caseId === _case?.id) {
      await definitionsApi.definitionUpdate({ definition });
    } else { // Create
      definition.caseId = _case?.id;
      definition.id = undefined;
      definition.isInternal = true;
      const created = await definitionsApi.definitionCreate({ definition });
      if (created.id) {
        setDefinitions(ds => {
          const update = [...ds!];
          update[index] = created;
          return update;
        });
      }
    }
  }

  function handleDefinitionRemove(index: number) {
    let remove: Definition[] = [];
    setDefinitions(ds => {
      const update = [...ds!];


      remove = update.splice(index, 1);

      return update;
    });

    setDeletingDefinitions(d => [...d].concat(remove.filter(x => x.id)));
  }

  function handleDefinitionRestore(index: number) {
    let restore: Definition[] = [];

    setDeletingDefinitions(ds => {
      const update = [...ds];

      restore = update.splice(index, 1);

      return update;
    });

    setDefinitions(d => [...d!].concat(restore));
  }

  const officeJurisdictions = firm?.offices?.flatMap(o => { return o.jurisdictions?.map(j => j.jurisdictionId ?? "") ?? "" }) ?? []



  return (
    <FormModal
      title={title}
      cancelText="Cancel"
      confirmText={confirm}
      show={isShow}
      disableConfirmButton={!shortTitle || !position || !caseType || !venue}
      onClose={doClose}
      onConfirm={doSave}
      size='lg'
      contentClassName='add-edit-case'
      preventBackgroundClick
      confirming={saving}
    >
      {!_case && showAlert && <Alert variant="info" dismissible onClose={() => setShowAlert(false)}><b>Did you know?</b> You don't need to create a Case to upload documents, you can add the Case during Document Confirmation.</Alert>}
      <Tab.Container id="case-modal-tabs" defaultActiveKey="general">
        <Nav className="case-modal-nav">
          <Nav.Item>
            <Nav.Link eventKey="general">General Details</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="admin">Admin Details</Nav.Link>
          </Nav.Item>
          {//don't show definitions or parties when creating new cases
            !!_case?.id &&
            <Nav.Item>
              <Nav.Link eventKey="definitions">Definitions</Nav.Link>
            </Nav.Item>
          }
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="general">
            <div className="full" >
              <div className="client-input-row">
                <label htmlFor='short-title' className="required">Short Title of Case</label>
                <Form.Control id='short-title' required value={shortTitle || undefined} onChange={e => setShortTitle(e.target.value)} />
              </div>
              <div className="client-input-row">
                <label htmlFor='case-number'>Case Number</label>
                <Form.Control id='case-number' value={caseNumber || undefined} onChange={e => setCaseNumber(e.target.value)} />
              </div>
            </div>
            <div className="full" >
              <div className="client-input-row full">
                <label htmlFor='full-title'>Full Title</label>
                <Form.Control id='full-title' value={fullTitle || undefined} onChange={e => setFullTitle(e.target.value)} />
              </div>
              <div className="full" >
                <div className="client-input-row">
                  <Form.Group>
                    <Form.Label className="required">Client Position</Form.Label>
                    <div className="checkbox-container">
                      <span>
                        <Form.Check
                          id={'propounding-party-plaintiff'}
                          type={'checkbox'}
                          label="Plaintiff"
                          checked={positionValues.includes(PartyPosition.Plaintiff)}
                          onChange={() => flipPosition(PartyPosition.Plaintiff)}
                          isInvalid={position === PartyPosition.Unknown}
                        />
                      </span>
                      <span>
                        <Form.Check
                          id={'propounding-party-cross-defendant'}
                          type={'checkbox'}
                          label="Cross-Defendant"
                          checked={positionValues.includes(PartyPosition.CrossDefendant)}
                          onChange={() => flipPosition(PartyPosition.CrossDefendant)}
                          isInvalid={position === PartyPosition.Unknown}
                        />
                      </span>
                      <span>
                        <Form.Check
                          id={'propounding-party-defendant'}
                          type={'checkbox'}
                          label="Defendant"
                          checked={positionValues.includes(PartyPosition.Defendant)}
                          onChange={() => flipPosition(PartyPosition.Defendant)}
                          isInvalid={position === PartyPosition.Unknown}
                        />
                      </span>
                      <span>
                        <Form.Check
                          id={'propounding-party-cross-complainant'}
                          type={'checkbox'}
                          label="Cross-Complainant"
                          checked={positionValues.includes(PartyPosition.CrossComplainant)}
                          onChange={() => flipPosition(PartyPosition.CrossComplainant)}
                          isInvalid={position === PartyPosition.Unknown}
                        />
                      </span>
                    </div>
                  </Form.Group>
                </div>
                <div className="client-input-row">
                  <Form.Group>
                    <Form.Label>Representative Action</Form.Label>
                    <div className="checkbox-container">
                      <Form.Check
                        id={'representative-ind'}
                        type={'radio'}
                        label="Individual"
                        inline
                        checked={!representative || representative === Representative.Individual}
                        value={Representative.Individual}
                        onChange={(e) => setRepresentative(e.currentTarget.value as Representative)}
                      />
                      <Form.Check
                        id={'representative-class'}
                        type={'radio'}
                        label="Class Action"
                        inline
                        checked={representative === Representative.ClassAction}
                        value={Representative.ClassAction}
                        onChange={(e) => setRepresentative(e.currentTarget.value as Representative)}
                      />
                      <Form.Check
                        id={'representative-paga'}
                        type={'radio'}
                        inline
                        label="PAGA"
                        checked={representative === Representative.Paga}
                        value={Representative.Paga}
                        onChange={(e) => setRepresentative(e.currentTarget.value as Representative)}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="full" >
              <div className="client-input-row">
                <label htmlFor='case-type' className="required">Case Type</label>
                <CaseTypeSelect
                  className='select'
                  options={options}
                  onChange={handleCaseTypeChange}
                  selected={caseType}
                />
              </div>
              <div className="client-input-row">
                <label htmlFor='judge'>Judge</label>
                <Form.Control id='judge' value={judge || undefined} onChange={e => setJudge(e.target.value)} />
              </div>
            </div>
            <div className="full" >
              <div className="client-input-row">
                <label htmlFor='jurisdiction'>Jurisdiction</label>
                {(officeJurisdictions?.length ?? 0) <= 1 ?
                  (<Form.Control id='jurisdiction' value={jurisdiction?.searchNames![0]!} disabled />)
                  : (<Form.Select
                    id="jurisdiction-select"
                    value={jurisdiction?.id ?? ""}
                    onChange={(event) => { setJurisdictionId(event.currentTarget.value); setVenue(""); }}
                    style={{ marginBottom: "8px" }}
                  >
                    <option value="" disabled>Please Select</option>
                    {jurisdictions?.filter(j => { return officeJurisdictions?.includes(j.id!) }).sort((a, b) => a.friendlyName!.localeCompare(b.friendlyName!)).map((jx, index) => (
                      <option key={index} value={jx?.id}>
                        {jx?.friendlyName}
                      </option>
                    ))}
                  </Form.Select>)}
              </div>
              <div className="client-input-row">
                <label htmlFor='venue' className="required">Venue</label>
                {jurisdiction?.id === CaliforniaId ? <Form.Select id='venue' required value={venue} onChange={e => setVenue(e.target.value)} >
                  <option value="" disabled>Please Select</option>
                  {jurisdiction?.venues?.sort(SortVenue).map((venue, index) => (
                    <option key={index} value={venue.id}>
                      {venue.shortName}
                    </option>
                  ))}
                </Form.Select> :
                  jurisdictionId === FederalId ?
                    <FederalSelect
                      setVenue={(e: string) => setVenue(e)}
                      venue={venue}
                      federalJurisdiction={jurisdiction}
                      jurisdictions={jurisdictions}
                      officeJxIds={firm.offices?.flatMap(of => of.jurisdictions?.flatMap(jx => jx.jurisdictionId ?? "") ?? "")}
                    />
                    :
                    (<Typeahead
                      onChange={(e: any) => setVenue(e[0]?.id)}
                      selected={(venue && [jurisdiction?.venues!.find(x => x.id === venue)].filter((x): x is NonNullable<typeof x> => x !== undefined)) || []}
                      isInvalid={!venue}
                      ref={typeaheadRef}
                      id="venue-select"
                      options={jurisdiction?.venues?.sort(SortVenue) || []}
                      placeholder="Select a Venue..."
                      labelKey={(o: any) => `${o.shortName}`} />)
                }
              </div>
            </div>
            <div className="full" >
              <div className="client-input-row">
                <label htmlFor='plaintiff'>Plaintiff(s)</label>
                <Form.Control id='plaintiff' value={plaintiff || undefined} onChange={e => setPlaintiff(e.target.value)} />
              </div>
              <div className="client-input-row">
                <label htmlFor='defendant'>Defendant(s)</label>
                <Form.Control id='defendant' value={defendant || undefined} onChange={e => setDefendant(e.target.value)} />
              </div>
            </div>
            <div className="client-input-row full">
              <SelectCreate id='case-tags' label="Tags" availableTags={availableTags} addingTags={addingTags} setAddingTags={setAddingTags} extraInfo={extraTagInfo} selected={tags} onChange={handleTagChange} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="admin">
            <div className="client-input-row">
              <label htmlFor='filed-date'>Filed Date</label>
              <Form.Control type='date' className='date-picker' id='filed-date' value={filedDate || undefined} onChange={e => setFiledDate(e.target.value)} />
            </div>
            <div className="client-input-row">
              <label htmlFor='trial-date'>Trial Date</label>
              <Form.Control type='date' className='date-picker' id='trial-date' value={trialDate || undefined} onChange={e => setTrialDate(e.target.value)} />
            </div>
            <div className="client-input-row">
              <label htmlFor='client-billing'>Billing Code</label>
              <Form.Control id='client-billing' value={billingCode} onChange={e => setBillingCode(e.target.value)} />
            </div>
            <h4>Case Contact Information</h4>
            <Form.Check type={'checkbox'}
              label="Use Client Contact info" checked={!contractOverrideOpen} onChange={(e) => unsetContactInfo(!e.target.checked)} />
            {contractOverrideOpen && <><div className="client-input-row">
              <label htmlFor='contact-first'>First Name</label>
              <Form.Control id='contact-first' value={contactFirst || undefined} onChange={e => setContactFirst(e.target.value)} />
            </div>
              <div className="client-input-row">
                <label htmlFor='contact-last'>Last Name</label>
                <Form.Control id='contact-last' value={contactLast || undefined} onChange={e => setContactLast(e.target.value)} />
              </div>
              <div className="client-input-row">
                <label htmlFor='contact-email'>Email Address</label>
                <Form.Control id='contact-email' value={contactEmail || undefined} onChange={e => setContactEmail(e.target.value)} />
              </div></>
            }
            <Attorneys users={users} assignedAttorneys={attorneys} topLevelAttorneys={client?.attorneys ?? []} setAttorneys={setAttorneys} />
          </Tab.Pane>
          {//don't show definitions or parties when creating new cases
            !!_case?.id &&
            <Tab.Pane eventKey="definitions">
              <Loading isLoading={!_case && !definitions}>
                <div className='definitions-container'>
                  {definitions?.sort((a, b) => {
                    if (!a.id && b.id) {
                      return 1
                    }

                    if (a.id && !b.id) {
                      return -1;
                    }

                    if (!a.term && b.term) {
                      return 1;
                    }

                    if (a.term && !b.term) {
                      return -1;
                    }

                    if (!a.term && !b.term) {
                      return 0;
                    }

                    return a.term!.localeCompare(b.term!);
                  }).map((def, index) => {
                    if (def.isDeleted) return null;
                    return (
                      <div key={`definition-${index}`}>
                        <label htmlFor={`definition-term-${index}`}>Term</label>
                        <Form.Control id={`definition-term-${index}`} value={def.term ?? ''} onChange={(e) => handleDefinitionChange(e.target.value, index, "term")} onBlur={() => handleDefinitionBlur(index)} />
                        <label htmlFor={`definition-alias-${index}`}>Aliases (Optional)</label>
                        <Form.Control id={`definition-alias-${index}`} value={def.aliases ?? ''} placeholder="Separate multiple aliases with a comma" onChange={(e) => handleDefinitionChange(e.target.value, index, "aliases")} onBlur={() => handleDefinitionBlur(index)} />
                        <label htmlFor={`definition-content-${index}`}>Content</label>
                        <Form.Control as='textarea' id={`definition-content-${index}`} value={def.content ?? ''} onChange={(e) => handleDefinitionChange(e.target.value, index, "content")} onBlur={() => handleDefinitionBlur(index)} />
                        <Button variant='link' onClick={() => handleDefinitionRemove(index)}>Remove</Button>
                      </div>
                    );
                  })}
                </div>
                <Button variant="outline-primary" size="sm" onClick={() => setDefinitions(d => [...d ?? [], { isInternal: true }])}>+ Add Another Term</Button>
                {!!deletingDefinitions.length && <h2>To Delete on Save:</h2>}
                {deletingDefinitions.map((def, index) => {
                  return (
                    <div key={`definition-delete-${index}`}>
                      {`${def.term} `}<ArrowCounterclockwise title="Undo" onClick={() => handleDefinitionRestore(index)} />
                    </div>);
                })}
              </Loading>
            </Tab.Pane>
          }
        </Tab.Content>
      </Tab.Container>
    </FormModal>
  );
}
